import {
  useUser,
} from "@clerk/clerk-react";
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faUser, faPhone } from '@fortawesome/free-solid-svg-icons';

import {
  faArrowUpRightFromSquare,
  faLocationDot,
  faStar,
  faXmark,
  faBuilding,
  faCircleCheck as solidCircleCheck,
} from '@fortawesome/free-solid-svg-icons';

import React, { useState } from 'react';
import musicalInstruments from '../../../../data/instruments';
import HeartButton from '../../buttons/HeartButton/HeartButton';
import {
  CardContainer,
  Icon,
  IconContainer,
  InstrumentIcon,
  Instruments,
  Name,
  ProfilePicture,
  RatingContainer,
  RatingIcon,
  RatingText,
  Tag,
  TagContainer,
} from './MainCardElements';

import { ADMIN, PLANNER, USER } from '../../../../config/profile/Roles';
import { IconButtonContainer } from "../../buttons/IconButton/IconButtonElements";
import { IconText } from "../../texts/TextsElements";
import Loader from "../../../../utils/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import { abbreviateSurname } from "../../../../utils/String";

const MainCard = ({ action=false, validator=true, imageSrc, planner = false, onClick, onRequest, profile, role, onValidate, onDisable, setShowLoginPopup, loading=false }) => {

  // console.log('role: ', role);
  const { isSignedIn } = useUser();

  

  const { firstName, lastName, genres, instruments, city, reviews } = profile;


  // console.log('Role: ', reviews);

  let rating = -1;
  
  if (reviews && reviews.length>0){
    rating = reviews.reduce((a,b)=>a+b.value,0)
    rating = rating/reviews.length
  }

  const handleValidateUser = () => {
    if (profile.valid) {
      onDisable(
        (firstName ? firstName : '') + ' ' + (lastName ? lastName : '')
      );
    } else {
      onValidate(
        (firstName ? firstName : '') + ' ' + (lastName ? lastName : '')
      );
    }
  };

  return (
    <>
      <CardContainer>
        <ProfilePicture src={imageSrc}  />
        {(action || role==ADMIN) && !loading && <IconContainer>
          <Icon icon={faUser} onClick={onClick} />
          {/* {(role === PLANNER ||
            role === USER) && (
              <HeartButton
                isFilled={clicked}
                onClick={(event) => {
                  if (!isSignedIn) {
                    setShowLoginPopup(true);
                  } else {
                    setClicked(!clicked);
                    event.stopPropagation();
                  }
                }}
              />
            )} */}
          {(role === PLANNER ||
            role === USER ) && (
              <Icon
                icon={faPhone}
                onClick={() => {
                  if (!isSignedIn) {
                    setShowLoginPopup(true);
                  } else {
                    onRequest(
                      (firstName ? firstName : '') +
                        ' ' +
                        (lastName ? lastName : '')
                    );
                  }
                }}
              />
            )}
          {role === ADMIN && !planner && validator && (
            <Icon
              icon={!profile.valid ? solidCircleCheck : faXmark}
              onClick={handleValidateUser}
              $color={!profile.valid && 'green'}
            />
          )}
        </IconContainer>}
        <Name>
          {(firstName ? firstName : '') + ' ' + (lastName ? role!=ADMIN?abbreviateSurname(lastName):lastName : '')}
        </Name>
        <div style={{display:'flex',margin:0, gap:"10px"}}>
          <IconButtonContainer
            icon={faLocationDot}
            color={'black'}
            size={'xs'}
          />
          <IconText $fontSize={'18px'}>
            {city?.label}
          </IconText>
        </div>
        {role === ADMIN && !window.location.href.includes("/planners") && (
          <TagContainer>
            {loading && <Tag
              $color={'green'}
              $bgColor={"#FADF72"}
            >
              <ClipLoader />
             Caricamento...
            </Tag>}
            {!loading && <Tag
              $color={profile.valid ? 'green' : '#F00'}
              $bgColor={profile.valid ? '#e8ffe8' : '#FFECEC'}
            >
              {!profile.valid ? 'non attivo' : 'attivo'}
              
            </Tag>}
          </TagContainer>
          
        )}
        {role === ADMIN && profile.premiumPlan && !window.location.href.includes("/planners") && (
          <TagContainer>
            
            {!loading && <Tag
              $color={profile.valid ? 'green' : '#F00'}
              $bgColor={'transparent'}
            >
              PREMIUM
            </Tag>}
          </TagContainer>
          
        )}
        {profile.close && <Name>Nelle vicinanze</Name>}
        
          {reviews && reviews.length>0 && <RatingContainer>
            <RatingIcon icon={faStar} />
            <RatingText>{rating}/5</RatingText>
            {/* <RatingIcon icon={faComment} /> */}
            <RatingText>({reviews.length} commenti)</RatingText>
          </RatingContainer>}
        
        {/* {!profile.valid && role === ADMIN && (
          <SecondaryButton
            onClick={() =>
              onValidate(
                (firstName ? firstName : '') +
                  ' ' +
                  (lastName ? lastName : 'Nill')
              )
            }
          >
            Abilita
          </SecondaryButton>
        )}
        {profile.valid && role === ADMIN && (
          <SecondaryButton
            onClick={() =>
              onDisable(
                (firstName ? firstName : '') +
                  ' ' +
                  (lastName ? lastName : 'Nill')
              )
            }
          >
            Disabilita
          </SecondaryButton>
        )} */}

        {planner && profile?.company && profile?.company?.name && <div style={{display:'flex',marginTop:10, gap:"10px"}}>
                  <IconButtonContainer
                    icon={faBuilding}
                    color={'black'}
                    size={'xs'}
                  />
                  <IconText $fontSize={'18px'}>
                    {profile?.company?.name}
                  </IconText>
                </div>}
       <Instruments>
          {instruments?.map((instrument, index) => {
            return (
              <Tag key={index}>{musicalInstruments.filter((x) => x.id === instrument.id)[0].label}</Tag>
              // <InstrumentIcon
              //   key={index}
              //   src={
              //     musicalInstruments.filter((x) => x.id === instrument.id)[0]
              //       .img
              //   }
              //   alt={instrument.name}
              // />
            );
          })}
        </Instruments>

        <TagContainer>
          {genres?.map((genre, index) => {
            const currentGenre = genres.find((g) => g.id === genre.id);
            return (
              <Tag key={index}>{currentGenre ? currentGenre?.label : ''}</Tag>
            );
          })}
        </TagContainer>

      </CardContainer>
    </>
  );
};

export default MainCard;
