import { useUser, useClerk } from '@clerk/clerk-react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { pecPattern } from '../../../../utils/String';
import PlaceSelector from '../../../functionals/PlaceSelector/PlaceSelector';
import Input from '../../../inputs/TextInput/TextInput';
import {
  TwoColumnGridContainer,
  TwoColumnGridWithMargin,
} from '../../Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import { ErrorText } from '../../texts/TextsElements';
import { InputWithError, StyledForm } from '../PersonalDataForm/PersonalDataFormElements';
import { updateBillingProfile } from '../../../../remote/payments';
import { useStateValue } from '../../../../stores/StateProvider';


const InvoiceDataForm = forwardRef(
  ({ tmpUserRef, submitForm, submitRef, formWidth, resetForm }, ref) => {
    const { user } = useUser();
    const [{ userData }] = useStateValue();

    const { session } = useClerk();
    const [profilePic, setProfilePic] = useState(null);
    const profilePicRef = useRef();
    profilePicRef.current = profilePic;
    const [errImage,setErrImage] = useState("")


    console.log("BILLING: ",userData)

    console.log("BILLING TMP: ",tmpUserRef)



    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      setValue,
      
    } = useForm({
      defaultValues: {
        idCode: '',
        vat: '',
        codDestinatario: '',
        pec: '',
        city: '',
        cap: '',
        address:''
      },
      mode: 'all',
    });

   

    useEffect(() => {
      window.addEventListener('error', (e) => {
        if (
          e.message ===
          'ResizeObserver loop completed with undelivered notifications.'
        ) {
          const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay'
          );

          if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
        }
      });
    }, []);

    useEffect(() => {
      if (tmpUserRef?.current?.invoice) {
        setValue('idCode', tmpUserRef.current?.invoice.idCode);
        setValue('vat', tmpUserRef.current?.invoice.vat);
        setValue('codDestinatario', tmpUserRef.current?.invoice.codDestinatario);
        setValue('pec', tmpUserRef.current?.invoice.pec);
        setValue('city', tmpUserRef.current?.invoice.city);
        setValue('cap', tmpUserRef.current?.invoice.cap);
        setValue('address', tmpUserRef.current?.invoice.address);
      }
      
    }, [setValue, tmpUserRef, resetForm]);






    const createBillingProfile = async (data) => {

      const jwtToken = await session.getToken();

      // update billing profile

      const userId = userData.id ?? tmpUserRef.current?.id;

      // if the userData is not present (eg. during onboarding)
      // take the information from the temporary user reference
      const name = userData.firstName ?? tmpUserRef.current?.firstName;
      const surname = userData.lastName ?? tmpUserRef.current?.lastName;
      const email = userData.email;
      const phone = userData.phone ?? tmpUserRef.current?.phone;
  
      const addressLine1 = data.address;
      const addressPostalCode = data.cap;
  
  
      let addressCity = undefined;
      let addressState = undefined;
      let addressCountry = undefined;

      if(data.city && data.city.label && data.city.label.split(',').length === 3) {
        const city = data.city.label.split(',');
        addressCity = city[0].trim();
        addressState = city[1].trim();
        addressCountry = city[2].trim() === 'Italia' ? 'IT' : undefined;
      }


      await updateBillingProfile(
        jwtToken,
        {
          userId: userId,
          email: email,
          name: name,
          surname: surname,
          phone: phone,
          addressLine1: addressLine1,
          addressPostalCode: addressPostalCode,
          addressCity: addressCity,
          addressState: addressState,
          addressCountry: addressCountry,
        }
      );

    }

    
    return (
      <StyledForm
        ref={ref}
        $width={formWidth}
        onSubmit={handleSubmit(async (data) => {
        
       
          await createBillingProfile(data);
         
          await submitForm({invoice: data});
        })}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <TwoColumnGridWithMargin>
          
            <TwoColumnGridContainer>      
              <InputWithError>
                <Input
                  type='text'
                  placeholder='Codice fiscale'
                  {...register('idCode', {
                    required: 'Il codice fiscale è obbligatorio',
                  })}
                  
                  $active={!!errors?.idCode?.message}
                />
                
                
                {errors.idCode && <ErrorText>{errors.idCode.message}</ErrorText>}
              </InputWithError>
              <InputWithError>
                <Input
                  type='text'
                  placeholder='Partita Iva'
                  {...register('vat')}
                  $active={!!errors?.vat?.message}
                />
                {errors.vat && (
                  <ErrorText>{errors.vat.message}</ErrorText>
                )}
              </InputWithError>
              <InputWithError>
                <Input
                  type='text'
                  placeholder='Codice Destinatario'
                  {...register('codDestinatario')}
                  $active={!!errors?.codDestinatario?.message}
                />
                {errors.codDestinatario && (
                  <ErrorText>{errors.codDestinatario.message}</ErrorText>
                )}
              </InputWithError>
              <InputWithError>
                  <Input
                  type='email'
                  placeholder='PEC'
                  {...register('pec')}
                  $active={!!errors?.pec?.message}
                />
                {errors.pec && (
                  <ErrorText>{errors.pec.message}</ErrorText>
                )}
              </InputWithError>
              <InputWithError>
                <Controller
                    control={control}
                    name='city'
                    rules={{ required: 'La città è obbligatoria' }}
                    render={({ field }) => (
                      <PlaceSelector
                          {...field}
                          type='text'
                          placeholder='Città'
                          onChange={(selected)=>field.onChange(selected)}
                          $active={!!errors?.city?.message}
                          name='city'
                          valueP={field.value}
                          bgColor="#f0efff"
                        />
                    )}
                  />
                {errors.city && <ErrorText>{errors.city.message}</ErrorText>}
              </InputWithError>
              <InputWithError>
                  <Input
                  type='text'
                  placeholder='CAP'
                  {...register('cap', {
                    required: 'Il CAP è obbligatorio'
                  })}
                  $active={!!errors?.cap?.message}
                />
                {errors.cap && (
                  <ErrorText>{errors.cap.message}</ErrorText>
                )}
              </InputWithError>
              
              
              
            </TwoColumnGridContainer>
          </TwoColumnGridWithMargin>
          <InputWithError>
                  <Input
                  type='text'
                  placeholder='Indirizzo'
                  {...register('address', {
                    required: 'L\'indirizzo è obbligatorio'
                  })}
                  $active={!!errors?.addrcess?.message}
                />
                {errors.address && (
                  <ErrorText>{errors.address.message}</ErrorText>
                )}
              </InputWithError>
          <button ref={submitRef} type='submit' style={{ display: 'none' }} />
        </div>
      </StyledForm>
    );
  }
);

export default InvoiceDataForm;
