

import { AWS } from "../config/URLs";


const updateBillingProfile = async (jwtToken, billingProfile) => {


    const response = await fetch(AWS + 'payments/update-billing-profile', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: "Bearer " + jwtToken,
        }),
        body: JSON.stringify(billingProfile),
    })


    return response;
}



const createSubscription = async (jwtToken, subscription) => {


    const response = await fetch(AWS + 'payments/create-subscription', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: "Bearer " + jwtToken,
        }),
        body: JSON.stringify(subscription),
    })


    return response;
}


const cancelSubcription = async (jwtToken, subscription) => {


    const response = await fetch(AWS + 'payments/cancel-subscription', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: "Bearer " + jwtToken,
        }),
        body: JSON.stringify(subscription),
    })


    return response;
}


const createPayment = async (jwtToken, payment) => {


    const response = await fetch(AWS + 'payments/create-payment', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: "Bearer " + jwtToken,
        }),
        body: JSON.stringify(payment),
    })

    return response;
}

const createPaymentApplication = async (jwtToken, payment) => {


    const response = await fetch(AWS + 'payments/create-payment-application', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: "Bearer " + jwtToken,
        }),
        body: JSON.stringify(payment),
    })

    return response;
}


const getPaymentConfig = async (jwtToken) => {

    const response = await fetch(AWS + 'payments/config', {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: "Bearer " + jwtToken,
        }),
    })

    return response;
}



export {
    updateBillingProfile,
    createSubscription,
    cancelSubcription,
    createPayment,
    createPaymentApplication,
    getPaymentConfig,
};