import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Popup from "../../components/functionals/Popup/Popup";
import SearchBar from '../../components/functionals/SearchBar/SearchBar';
import { InnerContainer, MainContainer, WholeScreenInnerContainer, WholeScreenInnerContainerBkg } from '../../components/presentationals/Containers/layouts/WholeScreen/WholeScreenElements';
import Footer from '../../components/presentationals/Footer/Footer';
import Header from '../../components/presentationals/Header/Header';
import DeletableButton from '../../components/presentationals/buttons/DeletableButton/DeletableButton';
import { RightFixedButton } from '../../components/presentationals/buttons/DeletableButton/DeletableButtonElements';
import SquareImage from '../../components/presentationals/media/SquareImage/SquareImage';
import { SmallText, Text, Title1, Title1Home } from '../../components/presentationals/texts/TextsElements';
import Images from '../../utils/Images';
import {
  Banner,
  CenterTextContainer,
  FirstHalfContainer,
  Heading,
  ImageStack,
  LineClamper,
  Paragraph,
  ProfileImage,
  Row,
  SecondHalfContainer,
  Subheading
} from './HomeElements';
import { MUSICIAN, PLANNER } from '../../config/profile/Roles';
import SelectableSquareButton from '../../components/presentationals/buttons/SelectableSquareButton/SelectableSquareButton';
import { HorizontalLayout } from '../../components/presentationals/Containers/layouts/HorizontalLayout/HorizontalLayout';
import HSplitScreen from '../../components/presentationals/Containers/layouts/HSplitScreen/HSplitScreen';
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { useStateValue } from '../../stores/StateProvider';
import { createSubscription } from '../../remote/payments';
import { useClerk } from '@clerk/clerk-react';
import Loader from '../../utils/Loader';



function Home({userData}) {
  const navigate = useNavigate();
  const [premiumPopup,setPremiumPopup] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const {session} = useClerk();

  const [paying,setPaying] = useState(false)


  useEffect(() => {
    let cookie = getCookie("showPremiumPopup");
    if (cookie){
      setPremiumPopup(true)
      eraseCookie("showPremiumPopup");
    }

    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 1024px)").matches;
      setIsMobile(isMobile);
    };

    handleResize(); // Controlla le dimensioni iniziali

    window.addEventListener("resize", handleResize); // Aggiungi un listener per rilevare i cambiamenti di dimensione

    return () => {
      window.removeEventListener("resize", handleResize); // Rimuovi il listener quando il componente viene smontato
    };
  }, []);


  const handleSubscriptionMusician = async() => {

    if(!paying) {
      
        await subcribeMusician();
      
    }

    setPremiumPopup(false)

  }

  const subcribeMusician = async() => {
    setPaying(true)

    const jwtToken = await session.getToken();


    let subscriptionId = undefined; // from 1 (inclusive) to 5 (inclusive)
    if (!userData || !userData.group || userData.group.groupType)
      subscriptionId = 1;
    else{
      switch(userData.group.groupType) {
        case '':
          subscriptionId = 1;
        break;
        case 'duo':
          subscriptionId = 2;
        break;
        case 'trio':
          subscriptionId = 3;
        break;
        case 'quartetto':
          subscriptionId = 4;
        break;
        case 'quintetto':
          subscriptionId = 5;
        break;
        case 'more':
          subscriptionId = 5; // the maximum is 5 people €25
        break;
        default:
          setPaying(false)
          return;
      }
    }

    const userId = userData.id;
    

    // pay
    
    const response = await createSubscription(
      jwtToken,
      {
        userId: userId,
        subscriptionId: subscriptionId,
      }
    );

    if(response.ok) {
        response.json().then(json => {            
            if(json.url) {
               window.location.href = json.url;
            } 
        });
    } else 
    setPaying(false)
  }


  
  

  return (
    <>
      
      
      <RightFixedButton onClick={() => {
                  navigate('/consulting');
                }} $bkgImg={Images.elements.consigliamiButton}>
              {/* <IconButton
                color={'black'}
                onClick={() => {
                  navigate('/consulting');
                }}
                icon={faEnvelope}
                text={`Consigliami`}
                display='flex'
              /> */}
      </RightFixedButton>
      {/* <FloatRightButton
          onClick={() => {
            navigate('/consulting');
          }}
              >
                <IconButtonContainer
                  icon={faPersonCircleQuestion}
                  color={'white'}
                  size='lg'
                />
      </FloatRightButton> */}

      

     

        {premiumPopup && <Popup
            title={'Vuoi abbonarti ora?'}
            content={""}
            onClose={() => {
              setPremiumPopup(false)
            }}
            onConfirm={() => {
              handleSubscriptionMusician()
              
            }}
            textAlign={'center'}
            loading={paying}
          >
            {!paying && <div style={{display:'flex',flexDirection:'column'}}>
              <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                I musicisti iscritti entro il 31 dicembre 2024 hanno diritto all’ <span style={{fontFamily: 'Raleway Bold'}}>abbonamento premium annuale</span> a soli <span style={{fontFamily: 'Raleway Bold'}}>4,99 €/anno* invece che a 19,90 €/anno*!</span>
              </Paragraph><Paragraph><span style={{fontFamily: 'Raleway Bold'}}>* esclusa IVA</span></Paragraph>
            </div>}
            {paying && <Text>Ti stiamo reinderizzando alla pagina di pagamento</Text>}
        
        </Popup>}
      <Header home={false} />

      
     
     <MainContainer  $bgColor={"#fff"}>
      <InnerContainer >
      {isMobile && (
          <SecondHalfContainer>
            <img src={Images.tmp.coverImg} style={{ padding:"20px", width: '100%' }} />
          </SecondHalfContainer>
        )}

        <FirstHalfContainer width={"65%"}>
          {/* <TitledContainer
            title='Esplora l’intera libreria o inizia subito la tua ricerca'
            margin={'0'}
            textAlign={'left'}
            textColor={'#000'}
          /> */}
          <Title1Home $bold={true}>Scopri i musicisti che hanno già scelto Orchestra!</Title1Home>
          {/* <LineClamper>
            <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Paragraph>
          </LineClamper> */}
          <SearchBar
            tutorial={true}
            disabledDate={true}
            onSearch={(place, date) => {
              const placeStr = encodeURIComponent(JSON.stringify(place));
              if (place) navigate('/musicians?place=' + placeStr + '&date=' + date);
              else navigate('/musicians');
            }}
          />
          {/* <Row $justifyContent={'flex-start'}>
            <ImageStack>
              <ProfileImage $size={20} src={Images.tmp.home} index={0} />
              <ProfileImage $size={20} src={Images.tmp.home} index={1} />
              <ProfileImage $size={20} src={Images.tmp.home} index={2} />
            </ImageStack>
            <SmallText $textColor={'#000'} $fontSize='14px' $textAlign='left'>
              2,500 persone hanno prenotato un artista con Orchestra nelle ultime
              24 ore
            </SmallText>
          </Row> */}
        
        </FirstHalfContainer>
        {!isMobile && (
          <SecondHalfContainer width={"30%"}>
            <img src={Images.tmp.coverImg} />
          </SecondHalfContainer>
        )}

      </InnerContainer>
     </MainContainer>

     <MainContainer style={{padding:0 }} $bgColor={"#fff"} >
      <InnerContainer paddingTop={"0px"} style={{ position: 'relative',border:'3px solid #F6F8ED', backgroundColor:"#F6F8ED", borderRadius:"10px", padding:"20px"  }}>
      <FirstHalfContainer width={"20%"} >
          <SquareImage src={Images.tmp.mission} />
            
              
            </FirstHalfContainer>
            <SecondHalfContainer width={"75%"}>
           
             <div style={{width:"100%", display:'flex',alignItems:'flex-start'}}>
              <Heading>La missione</Heading>
            </div>
            
              <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
                <img src={Images.logo.orchestra} style={{ height: '20px' }} /> è la piattaforma digitale che inserisce i musicisti dei conservatori di musica nazionali nel mondo del lavoro.
                La missione di <span style={{fontFamily: 'Raleway Bold'}}>Orchestra</span> è quella di nobilitare la professione artistica, restituendo spazio e rispettabilità al talento, con trasparenza e onestà. 
              </Paragraph>
              <Paragraph $textColor={'#000'} style={{ marginTop: '10px', textAlign:'justify' }}>
                <span style={{fontFamily: 'Raleway Bold'}}>Scopri nuove opportunità! I musicisti di Orchestra aumentano la loro visibilità e ricevono opportunità professionali, mentre i privati trovano talenti di qualità con facilità e sicurezza.</span>

              </Paragraph>
            
           
              
          </SecondHalfContainer>
          </InnerContainer>

     </MainContainer>
      

      {/* <HalfScreen title={"Sei un "} bgColor={'#fff'} >
        <HSplitScreen
                  width='100%'
                  outerMargin='20px 0'
                  
                  left={<><SelectableSquareButton
           
                    imageSrc={Images.icons.musician}
                    label={MUSICIAN}
                    // onClick={() => setType(MUSICIAN)}
                  /></>}
                  right={<><SelectableSquareButton
            
                    imageSrc={Images.icons.planner}
                    label={PLANNER}
                    // onClick={() => setType(PLANNER)}
                  />
                  
                  </>}
                />
        
      </HalfScreen> */}

      

      {/* <div id="mission"></div>
      <HalfScreen title={"Mission"} bgColor={'#fff'}  >
          
          <div style={{display:'flex', flexDirection:'column'}}>
            <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
              <img src={Images.logo.orchestra} style={{ height: '30px' }} /> è la piattaforma digitale che inserisce i musicisti dei conservatori di musica nazionali nel mondo del lavoro.
            </Paragraph>
            <Paragraph $textColor={'#000'} style={{ marginTop: '20px' }}>
            La missione di Orchestra è quella di nobilitare la professione artistica, restituendo spazio e rispettabilità al talento, con trasparenza e onestà. 
            </Paragraph>
            <Paragraph $textColor={'#000'} style={{ marginTop: '10px' }}>
            Scopri nuove opportunità! I musicisti di Orchestra aumentano la loro visibilità e ricevono opportunità professionali, mentre i privati trovano talenti di qualità con facilità e sicurezza.

           </Paragraph>
           </div>
      </HalfScreen> */}
      {/* <HalfScreen title={"Come funziona?"}  bgColor={'#fff'}>

      <div style={{display:'flex', flexDirection:'column'}}>
            <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
              
              <HSplitScreen
                width='100%'
                outerMargin='20px 0'
                
                left={<>
                <Heading>1. Registrati</Heading>
                <SquareImage src={Images.tmp.testGIF} /></>}
                right={<>
                <Heading>2. Crea il tuo annuncio </Heading>
                <SquareImage src={Images.tmp.testGIF} />
                </>}
              />
              
            </Paragraph>
            
            <Paragraph $textColor={'#000'} style={{ marginTop: '10px' }}>
              
             
            
            <HSplitScreen
                width='100%'
                outerMargin='20px 0'
                
                left={<>
                <Heading>3. Pubblica i tuoi annunci</Heading>
                <SquareImage src={Images.tmp.testGIF} />
                Trova il talento che fa per te! I privati possono creare annunci personalizzati specificando luogo, data, genere musicale, strumento e budget.
              
              E se non sai cosa scegliere, ti aiutiamo noi!
                </>
                }
                right={<>
                <Heading>4. Applica ora</Heading>
                <SquareImage src={Images.tmp.testGIF} />
                <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                    I musicisti possono candidarsi agli annunci, mostrando il loro talento. 
                    Gli utenti privati esaminano le candidature e scelgono il miglior professionista per la loro necessità.
                </Paragraph>
               
                </>}
              />
              


           </Paragraph>
          
           </div>
          

      </HalfScreen> */}
      {/* <HalfScreen title={"Promo estiva"} bgColor={'#fff'} >

      <LineClamper>
            <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                I musicisti iscritti entro il 21 settembre 2024 hanno diritto all’ <span style={{fontWeight:'bold'}}>abbonamento premium</span> a soli <span style={{fontWeight:'bold'}}>4,99 €* invece che a 19,90 €!</span>
                </Paragraph><Paragraph><span style={{fontWeight:'bold'}}>* esclusa IVA</span>


              </Paragraph>
              </LineClamper>
          

      </HalfScreen> */}
      {/* <HalfScreen bgColor={'#000'}>

          <FirstHalfContainer>
                <SquareImage src={Images.tmp.viviana} />
                
              </FirstHalfContainer>
              <SecondHalfContainer>
                
                  <LineClamper>
                <Paragraph $textColor={'#fff'} style={{ margin: '0' }}>
                  Crediamo nel valore del talento, della trasparenza e dell’onestà. Crediamo nell’impegno quotidiano di ogni musicista competente. La nostra missione è nobilitare la professione artistica, restituendole spazio e rispettabilità. 
                  Offriamo ai nostri fruitori opportunità concrete e dignitosamente riconosciute, ponendo la fiducia e la correttezza alla base di ogni scambio e comunicazione.
                  Chi si avvale dei nostri servizi entra in un mondo ricco di occasioni professionali, formative e di accrescimento personale

                </Paragraph>
              </LineClamper>

              </SecondHalfContainer>

      </HalfScreen>
      <HalfScreen bgColor={'#000'}>

          <FirstHalfContainer>
          <LineClamper>
                <Paragraph $textColor={'#fff'} style={{ margin: '0' }}>
                  Crediamo nel valore del talento, della trasparenza e dell’onestà. Crediamo nell’impegno quotidiano di ogni musicista competente. La nostra missione è nobilitare la professione artistica, restituendole spazio e rispettabilità. 
                  Offriamo ai nostri fruitori opportunità concrete e dignitosamente riconosciute, ponendo la fiducia e la correttezza alla base di ogni scambio e comunicazione.
                  Chi si avvale dei nostri servizi entra in un mondo ricco di occasioni professionali, formative e di accrescimento personale

                </Paragraph>
                </LineClamper>
               
                
              </FirstHalfContainer>
              <SecondHalfContainer>
                
              <SquareImage src={Images.tmp.viviana} />
              

              </SecondHalfContainer>

      </HalfScreen>

      <div id="come-funziona"></div>
      <WholeScreen title="Come funziona" large={true} bgColor={'#000'}>
        <VideoContainer $display='none'>
            <YouTube videoId={"..."} opts={{height: '390', width: '640'}} />
          </VideoContainer>
      </WholeScreen>

      <div id="partners"></div>
      <HalfScreen large={true} bgColor={'#000'}>
        <PartnersSlider />
      </HalfScreen>

      <WholeScreen bgColor={'#000'}>
        {isMobile && (
          <SecondHalfContainer>
            <img src={Images.tmp.coverImg} style={{ width: '100%' }} />
          </SecondHalfContainer>
        )}

        <FirstHalfContainer>
          <TitledContainer
            title='Esplora l’intera libreria o inizia subito la tua ricerca'
            margin={'0'}
            textAlign={'left'}
            textColor={'#fff'}
          />
          <LineClamper>
            <Paragraph $textColor={'#fff'} style={{ margin: '0' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Paragraph>
          </LineClamper>
          <SearchBar
            onSearch={(place, date) => {
              const placeStr = encodeURIComponent(JSON.stringify(place));
              if (place) navigate('/musicians?place=' + placeStr + '&date=' + date);
              else navigate('/musicians');
            }}
          />
          <Row $justifyContent={'flex-start'}>
            <ImageStack>
              <ProfileImage $size={20} src={Images.tmp.home} index={0} />
              <ProfileImage $size={20} src={Images.tmp.home} index={1} />
              <ProfileImage $size={20} src={Images.tmp.home} index={2} />
            </ImageStack>
            <SmallText $textColor={'#fff'} $fontSize='14px' $textAlign='left'>
              2,500 persone hanno prenotato un artista con Orchestra nelle ultime
              24 ore
            </SmallText>
          </Row>
        
        </FirstHalfContainer>
        {!isMobile && (
          <SecondHalfContainer>
            <img src={Images.tmp.coverImg} />
          </SecondHalfContainer>
        )}
      </WholeScreen> */}
      
      <MainContainer $bgColor={"#fff"}>
        <InnerContainer>
          <div style={{width:"100%", display:'flex',flexDirection:'column', gap:"50px"}}>
          <div style={{ width: "100%", display: 'flex', alignItems:'center', justifyContent:'center', flexWrap: 'wrap', alignItems: 'center' }}>
            <Subheading style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
              Prendi posto in <img src={Images.logo.orchestra} style={{ height: '30px', marginLeft: '5px' }} />:
            </Subheading>
            <Subheading style={{ margin: '0', marginLeft: '10px' }}>
              sostieni la musica!
            </Subheading>
          </div>
          
          <HSplitScreen
                  width='100%'
                  outerMargin='0'
                  
                  left={<>
                  <SelectableSquareButton
                    width={"80%"}
                    // selected={type === PLANNER}
                    // imageSrc={Images.icons.planner}
                    label={"Sei un "+PLANNER.toLowerCase()+"?"}
                    onClick={() => navigate("/auth/signup?t="+PLANNER)}
                  />
                  </>}
                  right={<><SelectableSquareButton
                    width={"80%"}
                    // selected={type === MUSICIAN}
                    // imageSrc={Images.icons.musician}
                    label={"Sei un "+MUSICIAN.toLowerCase()+"?"}
                    onClick={() => navigate("/auth/signup?t="+MUSICIAN)}
                  />
                  
                  </>}
                />
          </div>
        </InnerContainer>
     </MainContainer>
      <Banner style={{marginTop:"5%"}} >
        <WholeScreenInnerContainerBkg style={{ position: 'relative' }} $bkgImg={Images.tmp.banner}>
          <CenterTextContainer style={{backgroundColor:"#EEEDA3", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", zIndex:30}}>
          <Heading style={{ margin: '0' }}>Offerta lancio</Heading>
          
              <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                I musicisti iscritti entro il 31 dicembre 2024 hanno diritto all’ <span style={{fontFamily: 'Raleway Bold'}}>abbonamento premium annuale</span> a soli <span style={{fontFamily: 'Raleway Bold'}}>4,99 €/anno* invece che a 19,90 €/anno*!</span>
              </Paragraph><Paragraph><span style={{fontFamily: 'Raleway Bold'}}>* esclusa IVA</span>


              </Paragraph>
             
              <DeletableButton onClick={()=>navigate("/auth/signup?t="+MUSICIAN)}>Registrati</DeletableButton>
            
            
            {/* <Subheading style={{ margin: '0' }}>
              Prendi posto in <img src={Images.logo.orchestra} style={{ height: '30px' }} />
            </Subheading>
            <Subheading style={{ margin: '0' }}>
              sostieni la musica!
            </Subheading> */}
           
          </CenterTextContainer>

          {/* <BottomText>
            Oppure scopri Orchestra+, la 1° piattaforma di matching per musicisti
            d’Italia
          </BottomText>
          <DoubleDownArrow
            onClick={() =>
              window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
            }
          >
            <IconButtonContainer
              icon={faAngleDoubleDown}
              color='white'
              size='lg'
            />
          </DoubleDownArrow> */}
        </WholeScreenInnerContainerBkg>
      </Banner>

      <Footer />
    </>
  );
}

export default Home;
