import {
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  useClerk,
  useUser,
} from "@clerk/clerk-react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";

import { useEffect, useState } from "react";
import HorizontalSplitScreen from "./components/presentationals/Containers/layouts/HorizontalSplitScreen/HorizontalSplitScreen";
import MainLayout from "./components/presentationals/Containers/layouts/MainLayout/MainLayout";
import { ADMIN, MUSICIAN, USER } from "./config/profile/Roles";
import { getMyApplications } from "./remote/announcements";
import { getMe, setPwdAsReset } from "./remote/me";
import NotFoundPage from "./screens/NotFoundPage";
import Admin from "./screens/admin/Admin";
import Announcements from "./screens/announcements/Announcements";
import ChiSiamo from "./screens/chiSiamo/ChiSiamo";
import Consulting from "./screens/consulting/Consulting";
import Contacts from "./screens/contacts/Contacts";
import CookiesDeclaration from "./screens/cookiesDeclaration/CookiesDeclaration";
import DatiPersonali from "./screens/datiPersonali/datiPersonali";
import EventDetail from "./screens/eventDetail/eventDetail";
import Garlasco from "./screens/garlasco/Garlasco";
import GarlascoBando from "./screens/garlascoBando/garlascoBando";
import Home from "./screens/home/Home";
import HowItWorks from "./screens/howItWorks/HowItWorks";
import Me from "./screens/me/Me";
import Profile from "./screens/me/Profile";
import Mission from "./screens/mission/Mission";
import PublicProfileAuthGuard from "./screens/routing/PublicProfileAuthGuard";
import SearchMusicians from "./screens/searchMusicians/SearchMusicians";
import TerminiCondizioni from "./screens/terminiCondizioni/terminiCondizioni";
import { useStateValue } from "./stores/StateProvider";
import { SET_USER_DATA, USERS } from "./stores/actions/UserActions";
import RedirectSignedIn from "./userManagement/RedirectSignedIn";
import RedirectToAdmin from "./userManagement/RedirectToAdmin";
import Auth from "./userManagement/auth/Auth";
import ChangePwd from "./userManagement/changePwd/changePwd";
import Onboarding from "./userManagement/onboarding/Onboarding";
import getToken from "./userManagement/utilities";
import Loader from "./utils/Loader";
import { eraseCookie, getCookie } from "./utils/Cookies";



function MainRouter() {
  const { user, isSignedIn, isLoaded } = useUser();
  const [role, setRole] = useState(USER);
  const { session } = useClerk();
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useStateValue();

  const loadApplications = async()=>{
    let t = await getToken(session)
    let applications = await getMyApplications(t);
    return applications
  }


  async function loadUser() {
    let jwt = await getToken(session);
    let me = await getMe(jwt);

    setRole(me.role);
    // setRole(PLANNER)
    if (
      me.role != ADMIN &&
      !me.isOnboardingCompleted &&
      !document.location.href.includes("onboarding")
    ) {
      document.location.href = document.location.origin + "/onboarding";
      return;
    }

    // setUserData({ ...me, dateOfBirth: new Date(me.dateOfBirth)

    //
    // });

    let applications = []
    if (me.role==MUSICIAN){

      applications = await loadApplications(jwt)
      
    }

    dispatch({
      type: USERS,
      subtype: SET_USER_DATA,
      payload: {
        ...me,
        dateOfBirth: new Date(me.dateOfBirth),
        applications: applications
        // valid: false,
        // role: PLANNER,
      },
    });

    setLoading(false);
  }

  const refreshApplications= async()=>{
    let applications = []
    let jwt = await getToken(session)
    if (role==MUSICIAN){
      applications = await loadApplications(jwt)

      let userData = state.userData

      userData.applications=applications

      dispatch({
        type: USERS,
        subtype: SET_USER_DATA,
        payload: userData
      });
    }

  }
  

  useEffect(() => {
   
    if (isLoaded) {
      if (isSignedIn) {
        loadUser();
      } else setLoading(false);
    }
  }, [isLoaded, user, isSignedIn]);

  if (loading || !isLoaded) return <Loader />;

  console.log("User data: ",state.userData)

  async function complete() {

    let t = await getToken(session)
    await setPwdAsReset(t)
    
  }

  let cookie = getCookie("resetPwd")

  if (cookie){
    eraseCookie("resetPwd")
    complete()
    return <Loader />
  }
  if (state.userData && "resetPwd" in state.userData && state.userData.resetPwd==false){
      
      return (<Router>
        <Routes>
          <Route
            path="/auth/changePwd"
            element={
              <>
               
                   
                    < HorizontalSplitScreen innerWidth={'50%'}>
                      <ChangePwd user={user} />
                    </HorizontalSplitScreen>
            

              </>
            }
          />
          <Route
            path="*"
            element={
              <Navigate to="/auth/changePwd" replace />
            }
          />
         
        </Routes>
      </Router>)
  }
  if (role == ADMIN)
    return (
      <Router>
        <Routes>
          <Route
            path="/admin/*"
            element={
              <>
                <SignedIn>
                  <RedirectSignedIn>
                    <MainLayout noMargin>
                      <Admin user={user} />
                    </MainLayout>
                  </RedirectSignedIn>
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn redirectUrl={"/admin"} />
                </SignedOut>
              </>
            }
          />
          <Route path="*" element={<RedirectToAdmin />} />
        </Routes>
      </Router>
    );
  else
    return (
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home userData={state.userData} />
              </>
            }
          />
          <Route
            path="/consulting"
            element={
              <>
                <Consulting />
              </>
            }
          />
          <Route
            path="/howitworks"
            element={
              <>
                <HowItWorks />
              </>
            }
          />
          <Route
            path="/terminiCondizioni"
            element={
              <>
                <TerminiCondizioni />
              </>
            }
          />
          <Route
            path="/garlascoBando"
            element={
              <>
                <GarlascoBando />
              </>
            }
          />
          <Route
            path="/datiPersonali"
            element={
              <>
                <DatiPersonali />
              </>
            }
          />
          <Route
            path="/cookies"
            element={
              <>
                <CookiesDeclaration />
              </>
            }
          />
          
          <Route
            path="/chi-siamo"
            element={
              <>
                <ChiSiamo />
              </>
            }
          />
          <Route
            path="/garlasco"
            element={
              <>
                <Garlasco />
              </>
            }
          />
          <Route
            path="/mission"
            element={
              <>
                <Mission />
              </>
            }
          />
          <Route
            path="/announcements/:announcement"
            element={
              <>
                <SignedIn>
                  <MainLayout >
                    <EventDetail onApply={refreshApplications}/>
                  </MainLayout>
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            }
          />

          <Route
            path="/auth/*"
            element={
              <>
                <SignedIn>
                  <RedirectSignedIn />
                </SignedIn>
                <SignedOut>
                  <Auth />
                </SignedOut>
              </>
            }
          />

          <Route
            path="/onboarding/*"
            element={
              <>
                <SignedIn>
                  <Onboarding />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            }
          />
          <Route
            path="/contacts"
            element={
              <>
                
                  <Contacts />
               
              </>
            }
          />

          <Route
            path="/musicians"
            element={
              <>
                <MainLayout>
                  <SearchMusicians />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/announcements"
            element={
              <>
                <SignedIn>
                  <MainLayout>
                    
                      <Announcements onApply={refreshApplications} role={state.userData ? state.userData.role : null} />
                      
                  </MainLayout>
                </SignedIn>
                <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
            }
          />
          

          <Route
            path="/musicians/:id"
            element={
              <>
                <SignedIn>
                  <PublicProfileAuthGuard >
                    <MainLayout fullWidth={"100%"}>
                      <Profile noEdit={true} />
                    </MainLayout>
                  </PublicProfileAuthGuard>
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            }
          />

          <Route
            path="/me/*"
            element={
              <>
                <SignedIn>
                  <RedirectSignedIn>
                    <MainLayout fullWidth={"100%"}>
                      <Me user={user} />
                    </MainLayout>
                  </RedirectSignedIn>
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn redirectUrl={"/me"} />
                </SignedOut>
              </>
            }
          />

          {role == ADMIN && (
            <Route
              path="/admin/*"
              element={
                <>
                  <SignedIn>
                    <RedirectSignedIn>
                      <MainLayout noMargin>
                        <Me user={user} />
                      </MainLayout>
                    </RedirectSignedIn>
                  </SignedIn>
                  <SignedOut>
                    <RedirectToSignIn redirectUrl={"/me"} />
                  </SignedOut>
                </>
              }
            />
          )}

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    );
}

export default MainRouter;
