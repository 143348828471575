import { useClerk, useUser } from '@clerk/clerk-react';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'; // Sostituisci con l'icona desiderata
import { useEffect, useRef, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import TitledContainer from '../../../components/presentationals/Containers/TitledContainer/TitledContainer';
import HorizontalSplitScreen from '../../../components/presentationals/Containers/layouts/HorizontalSplitScreen/HorizontalSplitScreen';
import HorizontalBar from '../../../components/presentationals/bars/HorizontalBar/HorizontalBar';
import DeletableButton from '../../../components/presentationals/buttons/DeletableButton/DeletableButton';
import IconButton from '../../../components/presentationals/buttons/IconButton/IconButton';
import AboutYouForm from '../../../components/presentationals/forms/AboutYouForm/AboutYouForm';
import EventsListForm from '../../../components/presentationals/forms/EventsListForm/EventsListForm';
import GalleryForm from '../../../components/presentationals/forms/GalleryForm/GalleryForm';
import GenresForm from '../../../components/presentationals/forms/GenresForm/GenresForm';
import InstrumentsForm from '../../../components/presentationals/forms/InstrumentsForm/InstrumentsForm';
import MoreInfoForm from '../../../components/presentationals/forms/MoreInfoForm/MoreInfoForm';
import PaymentMethodsForm from '../../../components/presentationals/forms/PaymentMethods/PaymentMethodsForm';
import PersonalDataForm from '../../../components/presentationals/forms/PersonalDataForm/PersonalDataForm';
import UploadVideoForm from '../../../components/presentationals/forms/UploadVideo/UploadVideoForm';
import ProgressIndicator from '../../../components/presentationals/indicators/ProgressIndicator/ProgressIndicator';
import Musician from '../../../models/Musician';
import Planner from '../../../models/Planner';
import { updateMe } from '../../../remote/me';
import { uploadImage } from '../../../remote/musicians';
import Loader from '../../../utils/Loader';
import getToken from '../../utilities';
import { ButtonContainerDiv, ContentDiv, FooterDiv, HeaderDiv } from './OnboardingStepsElements';
import GroupDataForm from '../../../components/presentationals/forms/GroupDataForm/GroupDataForm';
import CompanyDataForm from '../../../components/presentationals/forms/CompanyDataForm/CompanyDataForm';
import InvoiceDataForm from '../../../components/presentationals/forms/InvoiceDataForm/InvoiceDataForm';
import SecondaryButton from '../../../components/presentationals/buttons/SecondaryButton/SecondaryButton';
import { setCookie } from '../../../utils/Cookies';
import { ToastContainer, toast } from 'react-toastify';

export default function OnboardingSteps({ isMusicianRole }) {
  const [onboardingStarted, setOnboardingStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorUploading, setErrorUploading] = useState("");
  const { user } = useUser();
  const { session } = useClerk();
  const navigate = useNavigate();

  let tmpUser;
  const tmpUserRef = useRef();

  useEffect(() => {
    if (isMusicianRole) {
      tmpUser = new Musician(user.id, user.emailAddresses[0].emailAddress);
      tmpUserRef.current = tmpUser;
    } else {
      tmpUser = new Planner(user.id, user.emailAddresses[0].emailAddress);
      tmpUserRef.current = tmpUser;
    }
  }, []);

  useEffect(() => {
    if (
      !onboardingStarted &&
      (document.location.href.includes('aboutYou') ||
        document.location.href.includes('moreInfo') ||
        document.location.href.includes('genres') ||
        document.location.href.includes('instruments') ||
        document.location.href.includes('invoice')
    )) {
      navigate('/onboarding/');
    }
  }, [navigate, onboardingStarted]);

  const ref = useRef();
  const submitRef = useRef();

  const [currentRef, setCurrentRef] = useState(null);

  useEffect(() => {
    // Accedi al ref solo dopo che il componente PersonalDataForm è stato reso

    setCurrentRef(ref);
  }, [ref]);

  const handleSubmit = async (data) => {

    try{
      console.log("Data: ",data)
      setErrorUploading("")
      let newTmpUser = data;
      if (newTmpUser == null) return;

      tmpUserRef.current = { ...tmpUserRef.current, ...newTmpUser };
      console.log('tmpUserRef.current: ', tmpUserRef.current);
      if (currentStep.next) {
        setOnboardingStarted(true);
        navigate(currentStep.next);
      } else {
        // console.log('Gallery: ', tmpUserRef.current.gallery);

        // tmpUserRef.current.gallery = tmpUserRef.current.gallery
        //   .map(async (x, i) => {
        //     if (x?.uploaded === 'true') {
        //       return x?.imageUrl;
        //     }
        //     try {
        //       const fileNameWithoutExtension = x.file.name
        //         .split('.')
        //         .slice(0, -1)
        //         .join('.');

        //       await uploadImage(
        //         x.file,
        //         tmpUserRef.current.id +
        //           '_' +
        //           fileNameWithoutExtension.replace(/\s/g, '_')
        //       );

        //       return new Promise((resolve, reject) =>
        //         resolve(
        //           'https://www.wespread.it/tmp/eventia/' +
        //             tmpUserRef.current.id +
        //             '_' +
        //             fileNameWithoutExtension.replace(/\s/g, '_')
        //         )
        //       );
        //     } catch (error) {
        //       return null;
        //     }
        //   })
        //   .filter((x) => x != null);

        // let r = await Promise.all(tmpUserRef.current.gallery);
        // // tmpUserRef.current.gallery=[] // TODO: temporary
        // tmpUserRef.current.gallery = r.map((url) => ({
        //   imageUrl: url,
        //   uploaded: 'true',
        // }));

        delete tmpUserRef.current.file

        let t = await getToken(session);
        setIsLoading(true);
        updateMe(
          {user:tmpUserRef.current, changeValid: true},
          t,
          (err) => {toast.error("Ops! C'è stato qualche problema: "+err+"!\n Riprova;"); setIsLoading(false)},
          () =>{
            if (isMusicianRole)
              setCookie("showPremiumPopup",true)
            navigate('/')
            setIsLoading(false)
          }
        );
        // if (isMusicianRole)
        //   updateMusician(
        //     tmpUserRef.current,
        //     (err) => console.log('hey'),
        //     () => navigate('/')
        //   );
        // else
        //   updatePlanner(
        //     tmpUserRef.current,
        //     (err) => console.log('hey'),
        //     () => navigate('/')
        //   );
      }
    }
    catch (error){
      toast.error("Ops! C'è stato qualche problema: "+error+"!\n Riprova;");
    }
  };

 
  const texts = isMusicianRole
    ? [
        {
          title: 'Completa il tuo profilo',
          subtitle: '',
        },
        {
          title: 'Completa il profilo della tua formazione',
          subtitle: '',
        },
        // {
        //   title: 'Raccontaci di te',
        //   subtitle: 'Parlaci un pò di te',
        // },
        {
          title: 'Raccontaci di te',
          subtitle: 'Parlaci un pò di te',
        },
        {
          title: 'In quali generi sei specializzato?',
          subtitle: 'Indicaci i tuoi cavalli di battaglia (max. 3)',
        },
        {
          title: 'Quali sono i tuoi compagni di viaggio?',
          subtitle: 'Seleziona i tuoi strumenti',
        },
        {
          title: 'Ci daresti i tuoi dati di fatturazione?',
          subtitle: 'Ci serviranno per la fattura',
        },
        // {
        //   title: 'Aggiungi la tua galleria',
        //   subtitle: '',
        // },
        // {
        //   title: 'Come ti paghiamo?',
        //   subtitle: '',
        // },
      ]
    : [
        {
          title: 'Completa il tuo profilo',
          subtitle: '',
        },
        {
          title: 'Raccontaci di te',
          subtitle: 'Parlaci un pò di te',
        },
        {
          title: 'Ci daresti i tuoi dati di fatturazione?',
          subtitle: 'Ci serviranno per la fattura',
        }
      ];

  

  if (isMusicianRole) {
    var currentStep = {
      id: 0,
      prev: '',
      link: 'personal',
      next: 'group',
    };
    if (document.location.href.includes('group'))
      currentStep = {
        id: 1,
        prev: 'personal',
        link: 'group',
        next: 'moreInfo',
      };
    else if (document.location.href.includes('moreInfo'))
      currentStep = {
        id: 2,
        prev: 'group',
        link: 'moreInfo',
        next: 'genres',
      };
    else if (document.location.href.includes('genres'))
      currentStep = {
        id: 3,
        prev: 'moreInfo',
        link: 'genres',
        next: 'instruments',
      };
    else if (document.location.href.includes('instruments'))
      currentStep = {
        id: 4,
        prev: 'genres',
        link: 'instruments',
        next: 'invoice',
      };
    else if (document.location.href.includes('invoice'))
        currentStep = {
          id: 5,
          prev: 'instruments',
          link: 'invoice',
          next: '',
        };
  } else {
    var currentStep = {
      id: 0,
      prev: '',
      link: 'personal',
      next: 'company',
    };
    if (document.location.href.includes('company'))
      currentStep = {
        id: 1,
        prev: 'personal',
        link: 'company',
        next: 'invoice',
      };
    else if (document.location.href.includes('invoice'))
      currentStep = {
        id: 2,
        prev: 'company',
        link: 'invoice',
        next: '',
      };
  }
  

  // console.log('User: ', user);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <HorizontalSplitScreen innerWidth={'80%'}>
          <HeaderDiv>
            <HorizontalBar flex={1} spaceBetween={true}>
              {currentStep.id !== 0 && (
                <IconButton
                  color={'black'}
                  onClick={() => navigate(currentStep.prev)}
                  icon={faArrowLeftLong}
                  text={'Torna indietro'}
                  display={'none'}
                />
              )}
              {/* {currentStep.id == 0 && (
              <DeletableButton onClick={() => signOut()}>Esci</DeletableButton>
            )} */}
            </HorizontalBar>
          </HeaderDiv>
          <ToastContainer pauseOnHover={false} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <ContentDiv>
              <TitledContainer
                centered='center'
                title={texts[currentStep.id].title}
                subtitle={texts[currentStep.id].subtitle}
                alignItems={true}
                
                textAlign={'center'}
                mobileMargin={'0'}
              >
                
                <Routes>
                  <Route
                    path='/'
                    element={
                      <PersonalDataForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                      />
                    }
                  />
                  <Route
                    path='group'
                    element={
                      <GroupDataForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                      />
                    }
                  />
                  <Route
                    path='company'
                    element={
                      <CompanyDataForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                      />
                    }
                  />
                  <Route
                    path='aboutYou'
                    element={
                      <AboutYouForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                        isMusicianRole={isMusicianRole}
                      />
                    }
                  />
                  <Route
                    path='invoice'
                    element={
                      <InvoiceDataForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                        isMusicianRole={isMusicianRole}
                      />
                    }
                  />
                  <Route
                    path='moreInfo'
                    element={
                      <MoreInfoForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                        isMusicianRole={isMusicianRole}
                      />
                    }
                  />
                  <Route
                    path='genres'
                    element={
                      <GenresForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                      />
                    }
                  />
                  <Route
                    path='instruments'
                    element={
                      <InstrumentsForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                      />
                    }
                  />
                  <Route
                    path='gallery'
                    element={
                      <GalleryForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                      />
                    }
                  />
                  <Route path='video' element={<UploadVideoForm ref={ref} />} />
                  <Route
                    path='personal'
                    element={
                      <PersonalDataForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                      />
                    }
                  />
                  <Route
                    path='payment'
                    element={
                      <PaymentMethodsForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                      />
                    }
                  />
                  <Route
                    path='events'
                    element={
                      <EventsListForm
                        ref={ref}
                        submitForm={handleSubmit}
                        tmpUserRef={tmpUserRef}
                        submitRef={submitRef}
                      />
                    }
                  />
                </Routes>
                
              </TitledContainer>
            </ContentDiv>
            <FooterDiv>
              <HorizontalBar
                flex={1}
                spaceBetween={true}
                direction={'column'}
                margin
              >
                <ProgressIndicator
                  totalSteps={texts.length}
                  currentStep={currentStep.id}
                />
                <ButtonContainerDiv>
                  {currentStep.id !== 0 ? (
                    <SecondaryButton
                      onClick={() => navigate(currentStep.prev)}
                      
                    >
                      Torna indietro
                    </SecondaryButton>
                  ) : (
                    <div></div>
                  )}
                  <DeletableButton
                    disabled={!currentRef || !currentRef.current}
                    onClick={() => {
                      submitRef.current.click();
                    }}
                  >
                    Continua
                  </DeletableButton>
                </ButtonContainerDiv>
              </HorizontalBar>
            </FooterDiv>
          </div>
        </HorizontalSplitScreen>
      )}
    </>
  );
}
