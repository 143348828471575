import { Route, Routes, useNavigate } from 'react-router-dom';
import TabBar from '../../components/presentationals/bars/TabBar/TabBar';
import { adminTabs } from '../../config/profile/ProfileTabs';
import Search from '../searchMusicians/SearchMusicians';
import Profile from '../me/Profile';
import Requests from '../me/requests/Requests';
import { useStateValue } from '../../stores/StateProvider';
import { OuterContainer } from '../../components/presentationals/Containers/layouts/MainLayout/MainLayoutElements';
import SearchMusicians from '../searchMusicians/SearchMusicians';
import Announcements from '../announcements/Announcements';
import AnnouncementApplications from '../announcementApplications/announcementApplications';
import EventDetail from '../eventDetail/eventDetail';

function Admin() {
  const navigate = useNavigate();
  const [{ userData }] = useStateValue();

  console.log('UserData: ', userData);

  return (
    <>
      <TabBar
        onClick={(id) => navigate(id)}
        tabs={adminTabs}
        position='fixed'
        top='80px'
      />
      <Routes>
        <Route
          path='/'
          element={
            <OuterContainer $adminMargin>
              <SearchMusicians userData={userData} />
            </OuterContainer>
          }
        />
        <Route
          path='musicians'
          element={
            <OuterContainer $adminMargin>
              <SearchMusicians userData={userData} />
            </OuterContainer>
          }
        />
        <Route
          path='musicians/:id'
          element={
            <OuterContainer $adminMargin>
              <Profile userData={userData} />
            </OuterContainer>
          }
        />
        <Route
          path='planners/:id'
          element={
            <OuterContainer $adminMargin>
              <Profile userData={userData} />
            </OuterContainer>
          }
        />
        <Route
          path='planners'
          element={
            <OuterContainer $adminMargin>
              <SearchMusicians userData={userData} />
            </OuterContainer>
          }
        />
        <Route
          path='announcements'
          element={
            <OuterContainer $adminMargin>
              <Announcements role={userData ? userData.role : null} />
            </OuterContainer>}
        />
        <Route
            path="announcements/:announcement/applications"
            element={
              <>
                <OuterContainer $adminMargin>
                  <AnnouncementApplications />
                </OuterContainer>
              </>
            }
          />
      </Routes>
    </>
  );
}

export default Admin;
