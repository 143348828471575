import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import HalfScreen from '../../components/presentationals/Containers/layouts/HalfScreen/HalfScreen';
import { WholeScreenInnerContainer } from '../../components/presentationals/Containers/layouts/WholeScreen/WholeScreenElements';
import Footer from '../../components/presentationals/Footer/Footer';
import Header from '../../components/presentationals/Header/Header';
import { SecondaryClickableText } from '../../components/presentationals/buttons/SecondaryButton/SecondaryButtonElements';
import SquareImage from '../../components/presentationals/media/SquareImage/SquareImage';
import { SmallText } from '../../components/presentationals/texts/TextsElements';
import Images from '../../utils/Images';
import {
  FirstHalfContainer,
  Paragraph,
  SecondHalfContainer
} from './GarlascoElements';
import DeletableButton from '../../components/presentationals/buttons/DeletableButton/DeletableButton';


function Garlasco() {
  const navigate = useNavigate();
  const [consulting,setConsulting] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [toggle,setToggle] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 1024px)").matches;
      setIsMobile(isMobile);
    };

    handleResize(); // Controlla le dimensioni iniziali

    window.addEventListener("resize", handleResize); // Aggiungi un listener per rilevare i cambiamenti di dimensione

    return () => {
      window.removeEventListener("resize", handleResize); // Rimuovi il listener quando il componente viene smontato
    };
  }, []);

  return (
    <>
      

      {/* {consulting && <Popup
            title={'Consigliami'}
            content={""}
            onClose={() => {
              setConsulting(false)
            }}
            onConfirm={() => {
              setConsulting(false)
            }}
            textAlign={'center'}
          >
            <div style={{display:'flex',flexDirection:'row'}}>

            <FirstHalfContainer>
            
            <LineClamper>
            <Paragraph $textColor={'#fff'} style={{ margin: '0' }}>
            ### Chi Siamo

            Benvenuti su **Orchestra**, l'agenzia di organizzazione eventi che connette musicisti talentuosi con organizzatori alla ricerca di performance indimenticabili. La nostra missione è trasformare ogni evento in un'esperienza unica grazie al potere della musica.

            Con un ampio network di artisti, dai solisti alle band, offriamo soluzioni musicali su misura per matrimoni, feste, eventi aziendali e molto altro. Collaboriamo strettamente con gli organizzatori per comprendere le loro esigenze e garantire professionalità e qualità in ogni dettaglio.

            Affidati a **Orchestra** per creare l'armonia perfetta per il tuo evento. La musica è la nostra passione, la tua soddisfazione è la nostra missione.
              </Paragraph>
          </LineClamper>
          </FirstHalfContainer>
          <SecondHalfContainer>
            <SquareImage src={Images.tmp.banner} />
          </SecondHalfContainer>

          </div>
        
        </Popup>} */}
      <Header toggle={toggle}/>
      <div onClick={()=>{setToggle(!toggle); console.log("Hei: ",toggle)}}>
      <HalfScreen bgColor={"white"}  >
      <WholeScreenInnerContainer  style={{ position: 'relative'}}>
      <FirstHalfContainer style={{padding:0}}>
            
            
            <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
                Orchestra è lieta di pubblicare il primo bando di audizione per il suo spettacolo inedito, <span style={{fontFamily:'Raleway Bold'}}>“Rinnovisi la giostra!”</span>.

               </Paragraph>
              <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
                  Ogni zona del parco – la casa dei fantasmi, il tunnel dell’amore, il carretto con i dolci e la ruota panoramica – è concepita come un’esperienza completa e inclusiva, integrando oltre alla musica diverse altre discipline artistiche, per offrire una serata immersiva e multisensoriale: danza, teatro, talk e interviste.

                </Paragraph>
                <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
                Il pubblico non è solo spettatore, ma un partecipante attivo della serata. Attraverso stazioni musicali interattive, performance itineranti e attività durante gli intermezzi, gli spettatori sono invitati a esplorare e interagire con gli artisti e le opere, vivendo la serata in modo personale e unico.

              </Paragraph>
              <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
              La tecnologia gioca un ruolo fondamentale nel creare un ambiente suggestivo e avveniristico: proiezioni, mapping, immagini e luci trasformano gli spazi del teatro, immergendo il pubblico in scenari fantastici, con quiz interattivi e votazioni in tempo reale.
              </Paragraph>
              <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
              <span style={{fontFamily:'Raleway Bold'}}>“Rinnovisi la Giostra!”</span> rappresenta il nostro obiettivo di rinnovare la tradizione operistica e teatrale, creando un'esperienza che onori la ricca eredità della musica classica e dell'opera, ma che introduca anche elementi nuovi e contemporanei.
              </Paragraph>
              <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
              Per questo <span style={{fontFamily:'Raleway Bold'}}>cerchiamo artisti dotati non solo di eccellente tecnica e vocalità, ma anche di presenza scenica e pronti a mettersi in gioco.</span>

              </Paragraph>
              <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
              Vuoi far parte di questo entusiasmante progetto?
              Iscriviti alle nostre audizioni, <span style={{fontFamily:'Raleway Bold'}}>clicca <span style={{fontFamily:'Raleway Bold',textDecoration:'underline' }} onClick={()=>navigate("/garlascoBando")} >qui</span> per scaricare il bando!</span>

              </Paragraph>
              
                <DeletableButton onClick={()=>navigate("/announcements/eb1afe75-6909-4bc6-83dd-b3acfd0b29bf")} >
                  Iscriviti alle audizioni in presenza per cantanti
                </DeletableButton>
                <DeletableButton onClick={()=>navigate("/announcements/7968b698-65ff-4af6-97a2-e97f115cc27a")} >
                  Iscriviti alla call for scores
                </DeletableButton>
         
            
              
            </FirstHalfContainer>
            <SecondHalfContainer style={{padding:40}}>
              
                <SquareImage src={Images.tmp.garlasco} />
              
              
          </SecondHalfContainer>
          </WholeScreenInnerContainer>

      </HalfScreen>
      </div>
      
        <Footer />
     


      
        

          
         
          
         

      
     

      

      

      

     
    </>
  );
}

export default Garlasco;
