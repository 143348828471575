import { useClerk, useSignIn, useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import TitledContainer from "../../components/presentationals/Containers/TitledContainer/TitledContainer";
import Input from "../../components/inputs/TextInput/TextInput";
import DeletableButton from "../../components/presentationals/buttons/DeletableButton/DeletableButton";
import PasswordInput from "../../components/inputs/PasswordText/PasswordInput";
import errorMessages from "../../utils/ClerkUtilities/ErrorsMessages";
import { ErrorText } from "../../components/presentationals/texts/TextsElements";
import { useNavigate } from "react-router-dom";
import { setPwdAsReset } from "../../remote/me";
import getToken from "../utilities";
import ClipLoader from "react-spinners/ClipLoader";
import { eraseCookie, getCookie } from "../../utils/Cookies";

const ChangePwd = () => {
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [complete, setComplete] = useState(false);
  const [secondFactor, setSecondFactor] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")
  const {session} = useClerk()
  const [loading, setLoading] = useState(false)

  const {user}  = useUser();

  const navigate = useNavigate()

  const { isLoaded, signIn, setActive } = useSignIn();

  if (!isLoaded) {
    return null;
  }

  // async function create(e) {
  //   e.preventDefault();
  //   await signIn
  //     ?.create({
  //       strategy: "reset_password_email_code",
  //       identifier: email,
  //     })
  //     .then((_) => {
  //       setSuccessfulCreation(true);
  //     })
  //     .catch((err) => console.error("error", err.errors[0].longMessage));
  // }

  // async function reset(e) {
  //   e.preventDefault();
  //   await signIn
  //     ?.attemptFirstFactor({
  //       strategy: "reset_password_email_code",
  //       code,
  //       password,
  //     })
  //     .then((result) => {
  //       if (result.status === "needs_second_factor") {
  //         setSecondFactor(true);
  //       } else if (result.status === "complete") {
  //         setActive({ session: result.createdSessionId });
  //         setComplete(true);
  //       } else {
  //         console.log(result);
  //       }
  //     })
  //     .catch((err) => console.error("error", err.errors[0].longMessage));
  // }

  async function handleChangePwd (){
    setLoading(true)

    if (!newPassword || !confirmNewPassword){
      setErrorMsg("Compila tutti i campi")
      setLoading(false)
      return;
    }

    if (newPassword!=confirmNewPassword){
      setErrorMsg("Le password non coincidono!")
      setLoading(false)
      return;
    }

    try {
      let a = await user.updatePassword({currentPassword: "OrchestraTmp2024", newPassword:newPassword,signOutOfOtherSessions:true})

      let t = await getToken(session)
      try {
        await setPwdAsReset(t)
        setLoading(false);
        navigate("/");
      }
      catch (e){
        setLoading(false);
        console.log("Error: ",e);
        navigate("/")

      }
  
    }
    catch (err) {
      

      setErrorMsg("Ci sono stati dei problemi, controlle la password inserite!");
      setLoading(false);
    }



  }

  return (
    <div
      style={{
        display: "flex",
       
        flexDirection: "column",
        gap: "1em",
      }}
     
    >
      {successfulCreation && !complete && (
        <TitledContainer
          margin={"0px"}
          title="Inserisci la tua email"
          centered="center"
          fullWidth
          textAlign={"center"}
        >
          <div style={{width:"90%", display:'flex',alignItems:'center',justifyContent:'center', flexDirection:'column'}}>

          
            <Input
              value={email}
              placeholder="Email..."
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* {error && <ErrorText style={{ textAlign: "left" }}>{error}</ErrorText>} */}
            <DeletableButton type="submit">Resetta</DeletableButton>
          </div>
        </TitledContainer>
      )}

      {!successfulCreation && !complete && (
        <TitledContainer
          title="Inserisci la nuova password"
          subtitle={email?"Una mail con il codice è stata mandata alla tua mail "+email:""}
          centered="center"
          fullWidth
          margin={"0px"}
          textAlign={"center"}
        >
          <div style={{width:"100%", display:'flex',alignItems:'center',justifyContent:'center', flexDirection:'column'}}>
            <div style={{width:"90%"}}>
              {/* <PasswordInput
                value={oldPassword}
                placeholder="Inserisci la vecchia password..."
                onChange={(e) => setOldPassword(e.target.value)}
              /> */}
            
              <PasswordInput
                value={newPassword}
                placeholder="Inserisci la nuova password..."
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <PasswordInput
                value={confirmNewPassword}
                placeholder="Conferma la nuova password..."
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
              
            </div>
            

            {!loading && <DeletableButton onClick={handleChangePwd}>Conferma password</DeletableButton>}
            {loading && <div style={{flex:1}}>
                <ClipLoader />
              </div>}
          </div>
        </TitledContainer>
      )}

      {complete && "You successfully changed you password"}
      {secondFactor && "2FA is required, this UI does not handle that"}
    </div>
  );
};

export default ChangePwd;
