import { subYears } from 'date-fns';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AnnouncementBudgets } from "../../../../config/AnnouncementConsts";
import PlaceSelector from "../../../functionals/PlaceSelector/PlaceSelector";
import LargeTextInput from "../../../inputs/LargeTextInput/LargeTextInput";
import Input from "../../../inputs/TextInput/TextInput";
import { TwoColumnGridContainer, TwoColumnGridWithMargin } from "../../Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer";
import { InputWithError, StyledDatePicker, StyledOption, StyledSelect } from "../PersonalDataForm/PersonalDataFormElements";
import { ErrorText, Text } from '../../texts/TextsElements';
import SecondaryButton from '../../buttons/SecondaryButton/SecondaryButton';
import Popup from '../../../functionals/Popup/Popup';
import SelectableCard from '../../cards/SelectableCard/SelectableCard';
import { Tag, TagContainer, TagIcon } from '../../cards/MainCard/MainCardElements';
import musicalInstruments from '../../../../data/instruments';
import { IconButtonContainer } from '../../buttons/IconButton/IconButtonElements';
import { HorizontalLayout } from '../../Containers/layouts/HorizontalLayout/HorizontalLayout';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import genres from '../../../../data/genres';
import { LabelText } from '../../../functionals/Switch/SwitchElements';
import { ADMIN } from '../../../../config/profile/Roles';
import { musicianPlans } from '../../../../config/musicianPlans';
import Switch from '../../../functionals/Switch/Switch';

const AnnouncementForm = forwardRef(
  ({ notes,submitForm, submitRef, pendingAnnouncement, role, handlePayment, disabled = false }, ref) => {
    const [details, setDetails] = useState(notes);
    const [instrumentPopup,setInstrumentPopup] = useState(false);
    const [genrePopup,setGenrePopup] = useState(false);
    const [selectedInstruments,setSelectedInstruments] = useState([]);
    const [selectedGenres,setSelectedGenres] = useState([]);
    const [group,setGroup] = useState(false);

  
    console.log("Pending announcvement: ",pendingAnnouncement)

    const detailsRef = useRef();
    detailsRef.current = details;

    

    const announcementBudgetOptions = AnnouncementBudgets.map(x=>({value:x,label:x}))

    const toggleInstrument = (instrument) => {
      console.log('Instrument: ', instrument);
  
      let s = selectedInstruments;
  
  
      let thr = (group?musicianPlans[control._fields.groupType._f.ref.value].number:control._fields.nMusicians._f.ref.value)
  
      console.log("Thr: ",thr)
      
      if (selectedInstruments.includes(instrument))
        s = selectedInstruments.filter((x) => x !== instrument);
      else if (selectedInstruments.length<thr) {
        s = selectedInstruments.concat([instrument]);
      }
  
     
  
      setSelectedInstruments(s);
      setValue("instruments",s)
    };

    const toggleGenre = (genre) => {
      console.log('Genre: ', genre);
  
      let s = selectedGenres;
  
      console.log('s: ', s);
  
      if (selectedGenres.includes(genre))
        s = selectedGenres.filter((x) => x !== genre);
      else if (selectedGenres.length<3) {
        s = selectedGenres.concat([genre]);
      }
  
      console.log('S: ', s);
  
      setSelectedGenres(s);
      setValue("genres",s)
    };


    useEffect(()=>{
      console.log(control._fields.nMusicians)
    })
    

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      control
    } = useForm({
      defaultValues: {
       instruments: [],
       genres: [],
       groupType:Object.keys(musicianPlans)[0]
      },
      mode: 'all',
    });

    const handleInstrumentToggle = (id) => {

      let tmp = [...selectedInstruments];
      if (selectedInstruments.some((instrument) => instrument === id)) {
        tmp = tmp.filter((x) => x !== id);
      } else if (tmp.length < 3) {
        tmp.push(id );
      }
      setSelectedInstruments(tmp);
      setValue("instruments",tmp)
    };

    const handleGenreToggle = (id) => {

      let tmp = [...selectedGenres];
      if (selectedGenres.some((genre) => genre === id)) {
        tmp = tmp.filter((x) => x !== id);
      } else if (tmp.length < 3) {
        tmp.push(id );
      }
      setSelectedGenres(tmp);
      setValue("genres",tmp)
    };
    

    return (
      <form
        ref={ref}
        onSubmit={pendingAnnouncement?handleSubmit(handlePayment):handleSubmit(async (data)=>submitForm(data,group))}
        style={{display:'flex', flexDirection:'column', gap:10}}
        
      >
        {pendingAnnouncement && <><Text>Vuoi pagare ora?</Text></>}
        {!pendingAnnouncement && <>

        {/* <TwoColumnGridWithMargin>
            <TwoColumnGridContainer>   */}
              <InputWithError>
                  <Input
                    type='text'
                    placeholder="Titolo dell'annuncio"
                    {...register('title', {
                      required: 'Il titolo è obbligatorio',
                    })}
                    
                    $active={!!errors?.title?.message}
                  />
                  
                  
                  {errors.title && <ErrorText>{errors.title.message}</ErrorText>}
                </InputWithError>
              <InputWithError>
                <Controller
                    control={control}
                    name='location'
                    rules={{ required: 'La città è obbligatoria' }}
                    render={({ field }) => (
                      <PlaceSelector
                          {...field}
                          type='text'
                          placeholder='Città'
                          onChange={(selected)=>field.onChange(selected)}
                          $active={!!errors?.city?.message}
                          name='city'
                          valueP={field.value}
                          bgColor="#f0efff"
                        />
                    )}
                  />
                {/* {errors.city && <ErrorText>{errors.city.message}</ErrorText>} */}
              </InputWithError>
              <InputWithError>
                <Controller
                  control={control}
                  name='date'
                  rules={{ required: 'La data dell\'evento è obbligatoria' }}
                  render={({ field }) => (
                    <StyledDatePicker
                      {...field}
                      selected={field.value}
                      onChange={(dateOfBirth) => field.onChange(dateOfBirth)}
                      dateFormat='dd-MM-yy'
                      placeholderText={'Data dell\' evento'}
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode='select'
                      $active={!!errors?.dateOfBirth?.message}
                     
                    />
                  )}
                />
                {/* {errors.dateOfBirth && <ErrorText>{errors.dateOfBirth.message}</ErrorText>} */}
              </InputWithError>

           
            {/* </TwoColumnGridContainer>
        </TwoColumnGridWithMargin> */}
        {role== ADMIN &&  <InputWithError>
                  <Input
                    type='number'
                    placeholder='Prezzo'
                    {...register('price', {
                     
                    })}
                    
                    $active={!!errors?.price?.message}
                  />
                  
                  
                  {errors.price && <ErrorText>{errors.price.message}</ErrorText>}
                </InputWithError>}
        
        <InputWithError>
                
                  <StyledSelect
                    name='group'
                   
                    // $active={!!errors?.groupType?.message}
                    {...register('budget', {
                      required: 'Il budget è obbligatorio',
                    })}
                  >
                    <StyledOption value=''>Seleziona il budget che hai</StyledOption>
                    {announcementBudgetOptions.map((option) => (
                      <StyledOption key={option.value} value={option.value}>
                        {option.label}
                      </StyledOption>
                    ))}
                  </StyledSelect>
                
                {/* {errors.budget && <ErrorText>{errors.budget.message}</ErrorText>} */}
              </InputWithError>

          <Switch labelLeft={"Cerchi una formazione?"} checked={group} onChange={()=>{ setGroup(!group)}} />
         
          {group && <InputWithError>
                
                <StyledSelect
                  name='group'
                 
                  // $active={!!errors?.groupType?.message}
                  {...register('groupType', {
                    required: 'Il tipo del gruppo è obbligatorio',
                  })}
                >
                  <StyledOption value=''>Cosa stai cercando?</StyledOption>
                  {Object.keys(musicianPlans).map((option) => (
                    <StyledOption key={option} value={option}>
                      {musicianPlans[option].label}
                    </StyledOption>
                  ))}
                </StyledSelect>
              
              {/* {errors.budget && <ErrorText>{errors.budget.message}</ErrorText>} */}
            </InputWithError>}
            {!group && <InputWithError>
                  <Input
                    type='number'
                    placeholder='Numero di musicisti'
                  
                    min={1}
                    max={5}
                    {...register('nMusicians', {
                      min: {
                        value: 1,
                        message: 'Il numero minimo di musicisti è 1'
                      },
                      max: {
                        value: 5,
                        message: 'Il numero massimo di musicisti è 5'
                      }
                     
                    })}
                    
                    $active={!!errors?.nMusicians?.message}
                  />
                  
                  
                  {errors.nMusicians && <ErrorText>{errors.nMusicians.message}</ErrorText>}
                </InputWithError>}
        
       

        {disabled && details && <Input disabled value={details} />}

        <HorizontalLayout
              $justifyContent='flex-start'
              style={{ marginTop: '10px' }}
            >
              {selectedInstruments && selectedInstruments.length>0 && <LabelText>Strumenti: </LabelText>}
              <TagContainer $display='flex'>
                {selectedInstruments &&
                  selectedInstruments.map((x, index) => {
                    const currentInstrument =  musicalInstruments.filter(y=>y.id==x)[0];
                    
                    return (
                      <Tag key={index}>
                        {/* <TagIcon
                          key={index}
                          src={
                            currentInstrument.img
                          }
                          alt={x.name}
                        /> */}
                        {currentInstrument.label}
                        <IconButtonContainer
                          icon={faXmark}
                          color={'black'}
                          style={{ paddingLeft: '5px' }}
                          onClick={() => {
                            handleInstrumentToggle(x);
                          }}
                        />
                      </Tag>
                    );
                  })}
                {selectedInstruments && selectedInstruments.length < (group?(!musicianPlans[control._fields.groupType._f.ref.value]?3:musicianPlans[control._fields.groupType._f.ref.value].number):control._fields.nMusicians._f.ref.value) && (
                  <Tag
                    $color={'white'}
                    $bgColor={'#a7a3ff'}
                    onClick={() => {
                      console.log('clicked');
                      setInstrumentPopup(true);
                    }}
                    $cursor = "pointer"
                  >
                    {!selectedInstruments.length?'Inserisci i tuoi strumenti':'Aggiungi'}
                  </Tag>
                )}
              </TagContainer>
            </HorizontalLayout>

            <HorizontalLayout
              $justifyContent='flex-start'
              style={{ marginTop: '10px' }}
            >
              {selectedGenres && selectedGenres.length>0 && <LabelText>Generi: </LabelText>}
              <TagContainer $display='flex'>
                {selectedGenres &&
                  selectedGenres.map((x, index) => {
                    const currentGenre =  genres.filter(y=>y.id==x)[0];
                   
                    
                    return (
                      <Tag key={index}>
                        
                        {currentGenre.label}
                        <IconButtonContainer
                          icon={faXmark}
                          color={'black'}
                          style={{ paddingLeft: '5px' }}
                          onClick={() => {
                            handleGenreToggle(x);
                          }}
                        />
                      </Tag>
                    );
                  })}
               
                {selectedGenres && selectedGenres.length < 3 && (
                  <Tag
                    $color={'white'}
                    $bgColor={'#a7a3ff'}
                    onClick={() => {
                      console.log('clicked');
                      setGenrePopup(true);
                    }}
                    $cursor="pointer"
                  >
                    {!selectedGenres.length?'Inserisci i tuoi generi':'Aggiungi'}
                  </Tag>
                )}
              </TagContainer>
            </HorizontalLayout>

        {instrumentPopup && (
        <Popup
          title={'Scegli fino a ' + (group?musicianPlans[control._fields.groupType._f.ref.value].number:control._fields.nMusicians._f.ref.value) +' strumenti'}
          onClose={() => setInstrumentPopup(false)}
        >
          <TwoColumnGridContainer>
            {musicalInstruments.map((x) => (
              <SelectableCard
                selected={selectedInstruments.includes(x.id)}
                onClick={() => {toggleInstrument(x.id)}}
                key={x.id}
              >
                {/* <TagIcon src={x.img} alt={x.name} $pt={'0'} /> */}
                <p>{x.label}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}

      {genrePopup && (
              <Popup
                title={'Scegli fino a 3 generi'}
                onClose={() => setGenrePopup(false)}
              >
                <TwoColumnGridContainer>
                  {genres.map((x) => (
                    <SelectableCard
                      selected={selectedGenres.includes(x.id)}
                      onClick={() => {toggleGenre(x.id)}}
                      key={x.id}
                    >
                      
                      <p>{x.label}</p>
                    </SelectableCard>
                  ))}
                </TwoColumnGridContainer>
              </Popup>
            )}

       
        {!disabled && (
          <LargeTextInput
            type="text"
            value={detailsRef.current}
            width={"100%"}
            {...register('description', {
              required: 'La descrizione è obbligatoria',
            })}
            onChange={(e) => setDetails(e.target.value)}
            placeholder="Scrivi qui ulteriori dettagli..."
            margin={"10px 0 0 0"}
          />
        )}
        
        </>}
        <button ref={submitRef} type='submit' style={{ display: 'none' }} />
        </form>
    );
  }
);

export default AnnouncementForm;
