import Italiano from "../assets/icons/languages/Italiano.png";
import Inglese from "../assets/icons/languages/Inglese.png";
import francese from "../assets/icons/languages/francese.png";
import tedesco from "../assets/icons/languages/tedesco.png";
import china from "../assets/icons/languages/china.png";
import japan from "../assets/icons/languages/japan.png";
import russia from "../assets/icons/languages/russia.png";
import saudiArabia from "../assets/icons/languages/saudi-arabia.png";
import turkey from "../assets/icons/languages/turkey.png";
import spanish from "../assets/icons/languages/spain.png";

const languagesList = [
  { id: 0, label: "Italiano", img: Italiano },
  { id: 1, label: "Inglese", img: Inglese },
  { id: 2, label: "Francese", img: francese },
  { id: 3, label: "Cinese", img: china },
  { id: 4, label: "Tedesco", img: tedesco },
  { id: 5, label: "Giapponese", img: japan },
  { id: 6, label: "Coreano", img: tedesco },
  { id: 7, label: "Russo", img: russia },
  { id: 8, label: "Turco", img: turkey },
  { id: 9, label: "Arabo", img: saudiArabia },
  { id: 10, label: "Spagnolo", img: spanish },

];

export default languagesList;
