import { useClerk, useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { newMusician } from "../remote/musicians";
import { newPlanner } from "../remote/planners";
import { ADMIN } from "../stores/actions/AdminActions";
import { eraseCookie, getCookie } from "../utils/Cookies";
import getToken from "./utilities";
import { useStateValue } from "../stores/StateProvider";

const RedirectSignedIn = ({ children }) => {
  const { session } = useClerk();
  const navigate = useNavigate();
  const [{userData}] = useStateValue();
  const [loading,setLoading] = useState(true);
  const user = useUser()


  useEffect(() => {
    let tmpMusician = getCookie("tmpMusician");
    let tmpPlanner = getCookie("tmpPlanner");

    async function newUser(tmpMusician, tmpPlanner) {
      let t = await getToken(session);

      if (tmpMusician) {
     
        newMusician(
          JSON.parse(tmpMusician),
          t,
          (err) => {
            //setIsLoading(false);
            setLoading(false)
            //setError(err?.errors?.[0]?.message);
          },
          (succ) => {
            console.log("Succ in creating user: ", succ);
            //setIsLoading(false);
            setLoading(false)
            navigate("/onboarding");
          }
        );
        eraseCookie("tmpMusician");
        return;
      }
      if (tmpPlanner) {
        console.log("PLANNER: ", JSON.parse(tmpPlanner));
        newPlanner(
          JSON.parse(tmpPlanner),
          t,
          (err) => {
            //setIsLoading(false);
            //setError(err?.errors?.[0]?.message);
            setLoading(false)
          },
          (succ) => {
            console.log("Succ in creating user: ", succ);
            //setIsLoading(false);
            setLoading(false)
            navigate("/onboarding");
          }
        );
        eraseCookie("tmpPlanner");
        return;
      }
    }

    if (tmpMusician || tmpPlanner) {
      newUser(tmpMusician, tmpPlanner);
      return;
    }
    else{
      setLoading(false)
    }
  }, []); // L'array vuoto [] garantisce che questa chiamata venga eseguita solo una volta all'inizio


  if (loading)
    return <></>
  else if (userData && userData.role == ADMIN)
    return <Navigate to="/admin" replace={true} />;
  else if (children) {
    return children;
  } else return <Navigate to="/me" replace={true} />;
};

export default RedirectSignedIn;
