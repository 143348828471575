import bando from "../../assets/bando.pdf";


function GarlascoBando() {

 

  return (
    <div style={{display:'flex',width:"100%", height:"100vh",}}>
      <object data={bando} type="application/pdf" width="100%" height="100%">
          <p>Scarica <a href="https://orchestra.srl/static/media/bando.fa88d3f3a68d71c8419b.pdf">qui</a> il bando!</p>
      </object>
      
    </div>
  );
}

export default GarlascoBando;
