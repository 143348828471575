import {
  useClerk,
  useUser,
} from "@clerk/clerk-react";
import Slider from 'rc-slider';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import Popup from '../../components/functionals/Popup/Popup';
import SearchBar from '../../components/functionals/SearchBar/SearchBar';
import { TwoColumnGridAnnouncementContainer, TwoColumnGridContainer, TwoColumnGridWithMargin } from '../../components/presentationals/Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import HorizontalBar from '../../components/presentationals/bars/HorizontalBar/HorizontalBar';
import DeletableButton from '../../components/presentationals/buttons/DeletableButton/DeletableButton';
import SecondaryButton from '../../components/presentationals/buttons/SecondaryButton/SecondaryButton';
import AnnouncementCard from "../../components/presentationals/cards/AnnouncementCard/AnnouncementCard";
import SelectableCard from '../../components/presentationals/cards/SelectableCard/SelectableCard';
import AnnouncementForm from "../../components/presentationals/forms/AnnouncementForm/AnnouncementForm";
import {
  SmallText, Text
} from '../../components/presentationals/texts/TextsElements';
import { AnnouncementStates } from "../../config/AnnouncementConsts";
import { ADMIN, MUSICIAN, PLANNER } from '../../config/profile/Roles';
import genres from '../../data/genres';
import musicalInstruments from '../../data/instruments';
import Announcement from "../../models/Announcement";
import { getAnnouncements, newAnnouncement } from "../../remote/announcements";
import { useStateValue } from '../../stores/StateProvider';
import SignInPopup from "../../userManagement/signIn/signInPopup";
import getToken from '../../userManagement/utilities';
import { haversineDistance } from '../../utils/Geolocalization';
import Loader from "../../utils/Loader";
import { createPayment } from "../../remote/payments";
import { StyledOption, StyledSelect } from "../../components/presentationals/forms/PersonalDataForm/PersonalDataFormElements";
import { musicianPlans, SINGOLO } from "../../config/musicianPlans";
import { useSearchParams } from 'react-router-dom';

function Announcements({onApply}) {
  const [{userData}] = useStateValue();
  const [place, setPlace] = useState('');
  const [reset, setReset] = useState(false);

  const [searchParams] = useSearchParams();
    
    // Controlla se il parametro 'new' è definito
  const isNewDefined = searchParams.has('new');




  const [date, setDate] = useState('');
  const [loadingAnnouncements,setLoadingAnnouncements] = useState(true)
  const [adding,setAdding] = useState(false)

  const [unfilterData,setUnfilterData] = useState();

  const [groupFilter,setGroupFilter] = useState('');

  const [dateR, setDateR] = useState('');

  const [maxDistance, setMaxDistance] = useState(80);

  const [data, setData] = useState([]);
  const [closeData, setCloseData] = useState([]);

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showClosest, setShowClosest] = useState(false)

  const [newAnnouncementPopup, setNewAnnouncementPopup] = useState(isNewDefined)

  const [actionOpen,setActionOpen] = useState(-1)
  
  const announcementFormRef = useRef()
  const announcementSubmitFormRef = useRef()

  const [statusFilter, setStatusFilter] = useState(AnnouncementStates.OPEN)

  const dateRef = useRef();
  dateRef.current = date;
  const dateRRef = useRef();
  dateRRef.current = dateR;
  const placeRef = useRef();
  placeRef.current = place;

  const [notActiveFilter, setNotActiveFilter] = useState(
    userData?.role == ADMIN
  );

  const { session } = useClerk();
  const { isSignedIn } = useUser();


  const updateData = async () => {

    let t = await getToken(session)
    let announcements = await getAnnouncements(t);

    console.log('announcements', announcements);
    setUnfilterData(announcements);
    
    const urlParams = new URLSearchParams(window.location.search);
    const placeParam = urlParams.get('place');
    const dateParam = urlParams.get('date');

    let p = null;

    if (placeParam) {
      p = JSON.parse(decodeURIComponent(placeParam));
      setPlace(p);
    }

    if (dateParam) {
      setDate(dateParam);
      setDateR(new Date(dateParam));
    }

    

    if (placeParam && p != null) {
      setData(
        announcements.filter(
          (x) =>{

            
            return x.location &&
            (x.location.raw.osm_id === p.raw.osm_id ||
              filterCloseCities(x.location, p, 0, 10))
            
          }
        )
      );

      let closeD = announcements.filter(
        (x) =>
          x.location &&
          x.location.raw.osm_id !== p.raw.osm_id &&
          filterCloseCities(x.location, p,10,maxDistance)
      )

      console.log(closeD)

      setCloseData(
        closeD
      );
    } else {
      setData(
        announcements.map((x) => {
          x.close = false;
          return x;
        })
      );
      setCloseData([]);
    }
    setLoadingAnnouncements(false)
  };

 
  
  

  useEffect(() => {
    updateData();
  }, [window.location.href]);

  useEffect(() => {
    if(reset){
      updateData();
      setReset(false)
    }
  }, [reset]);

  useEffect(()=>{
    if (unfilterData && place){
      setCloseData(
        unfilterData.filter(
          (x) =>
            x.location &&
            x.location.raw.osm_id !== place.raw.osm_id &&
            filterCloseCities(x.location, place,10,maxDistance)
        )
      );
      }
  },[maxDistance])


  useEffect(() => {
    if (isSignedIn) setShowLoginPopup(false);
  }, [isSignedIn]);

  const filterCloseCities = (cityA, cityB,minD,maxD) => {
   
    const distance = haversineDistance(cityA.y, cityA.x, cityB.y, cityB.x);

    // Considera gli utenti entro 10 km dalla città target
    return distance>=minD && distance <= maxD;
  };

  const navigate = useNavigate();

  const [limit, setLimit] = useState(6);

  const [pendingAnnouncement,setPendingAnnouncement] = useState(null);

  const [instrumentPopup, setInstrumentPopup] = useState(false);

  const [genrePopup, setGenrePopup] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const heightMarks = {
    10: '10Km',
    
    20: '20Km',
    30: '30Km',

    40: '40Km',

    50: '50Km',

    60: '60Km',

    70: '70Km',

    80: '80Km'
  };


  const filterDataByGenres = (item) => {
    if (selectedGenres.length > 0) {
      return item.genres.find((x) => selectedGenres.includes(x.id));
    }

    return true;
  };

  const filterDataByInstruments = (item) => {
    if (selectedInstruments.length > 0) {
      return item.instruments.find((x) => selectedInstruments.includes(x.id));
    }

    return true;
  };

  const filterDataByType = (item) => {

    
    if (statusFilter) {
      return item.status==statusFilter;
    }

    return true;
  };

  const filterDataByGroupType = (item) => {
    if (groupFilter=="") 
        return true;
      
    if (groupFilter == SINGOLO && !item.group)
      return true;

    console.log("Item: ", item)
    console.log("GroupFilter: ",groupFilter)
    if (item.groupType==groupFilter)
      return true;

    return false;
  };

  const filterData = (item) => {

    return (
      filterDataByGroupType(item) && 
      filterDataByType(item) && 
      filterDataByGenres(item) &&
      filterDataByInstruments(item)
    );
  };

  const limits = [5,6,6,8,10]

  const handleSubmit = async (data, group) => {

    let limit = 5

    if (!group && data.nMusicians>=1 && data.nMusicians<=5 ){
      limit = limits[data.nMusicians-1]
    
    }



    setAdding(true)
    let announcement = new Announcement("-1",data.title,new Date(),data.location,data.date,data.genres,data.instruments,data.budget,data.description,data.price, group,group?data.groupType:null,group?null:data.nMusicians, limit, userData.role )

   

    let jwt = await getToken(session)
    
    let ann = await newAnnouncement(announcement,jwt)
    if (userData.role==ADMIN){
      setNewAnnouncementPopup(false);
      setPendingAnnouncement(null)
      
    }
    else{
      setPendingAnnouncement(ann)
    }
    // setNewAnnouncementPopup(false)
    await updateData()
    setAdding(false)
  }

 
  const toggleInstrument = (instrument) => {
    console.log('Instrument: ', instrument);

    let s = selectedInstruments;

    console.log('s: ', s);

    if (selectedInstruments.includes(instrument))
      s = selectedInstruments.filter((x) => x !== instrument);
    else if (selectedInstruments.length<5) {
      s = selectedInstruments.concat([instrument]);
    }

    console.log('S: ', s);

    setSelectedInstruments(s);
  };

  const toggleGenre = (genre) => {
    let s = selectedGenres;

    console.log('s: ', s);

    if (selectedGenres.includes(genre))
      s = selectedGenres.filter((x) => x !== genre);
    else if (selectedGenres.length<3){
      s = selectedGenres.concat([genre]);
    }

    console.log('S: ', s);

    setSelectedGenres(s);
  };

  const handlePaymentAnnouncement = async(id) => {

    setAdding(true)
    const jwtToken = await session.getToken();

    
    // pay
    
    const response = await createPayment(
      jwtToken,
      {
        userId: userData.id,
        announcementId: id,
      }
    );

    if(response.ok) {
        response.json().then(json => {            
            if(json.url) {
               window.location.href = json.url;
            } 
        });
    } 

    setPendingAnnouncement(null);
    setNewAnnouncementPopup(false);
    await updateData()
    setAdding(false);
  
  }

  



  return (
    <>
      {showLoginPopup && (
        <SignInPopup setShowLoginPopup={() => setShowLoginPopup(false)} />
      )}
      
      <HorizontalBar spaceBetween={true} margin='30px 0 0 0'>
        <SearchBar

          onSearch={(place, date) => {
            if (place)
              navigate('/announcements?place=' + JSON.stringify(place));
            else navigate('/announcements');
          }}
          disabledDate={true}
          place={place}
          date={date}
        />
      </HorizontalBar>

      
        <HorizontalBar margin='30px 0px 20px 0px' gap='20px' spaceBetween direction='column' mediaQuery={786}>
          <div style={{ display: 'flex', gap: '20px' }}>
            {selectedInstruments.length > 0 ? (
              <DeletableButton
                padding='12px 40px 12px 20px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
                onDelete={() => setSelectedInstruments([])}
              >
                {selectedInstruments
                  .map((x, i) => (
                    <span key={i}>{musicalInstruments.filter(y=>y.id==x)[0].label}</span>
                  ))
                  .reduce((prev, curr, i) => [prev, ', ', curr])}
              </DeletableButton>
            ) : (
              <SecondaryButton onClick={() => setInstrumentPopup(true)}>
                Strumenti
              </SecondaryButton>
            )}

            {selectedGenres.length > 0 ? (
              <DeletableButton
                padding='12px 40px 12px 20px'
                onDelete={() => setSelectedGenres([])}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {selectedGenres
                  .map((x) => <span style={{}}>{genres[x].label}</span>)
                  .reduce((prev, curr, i) => [prev, ', ', curr])}
              </DeletableButton>
            ) : (
              <SecondaryButton onClick={() => setGenrePopup(true)}>
                Generi
              </SecondaryButton>
            )}

            <StyledSelect
                    name='group'
                    onChange={(option)=>setGroupFilter(option.target.value)}
                  
                    // $active={!!errors?.groupType?.message}
                    
                  >
                  <StyledOption value=''>Cosa stai cercando?</StyledOption>
                  <StyledOption value={SINGOLO}>{SINGOLO}</StyledOption>
                  {Object.keys(musicianPlans).map((option) => (
                    <StyledOption key={option} value={option}>
                      {musicianPlans[option].label}
                    </StyledOption>
                  ))}
              </StyledSelect>
                          

           
          </div>
          {(place || date || !!selectedGenres.length || !!selectedInstruments.length) &&  (
            <div>
              <SecondaryButton
                onClick={() => {
                  setDate('');
                  setDateR('');
                  setPlace('');
                  setCloseData([]);
                  setShowClosest(false);
                  setLoadingAnnouncements(true);
                  setSelectedGenres([])
                  setSelectedInstruments([])
                  setMaxDistance(80);
                  setReset(true);
                  navigate('/announcements');
                }}
              >
                Reset
              </SecondaryButton>
            </div>
          )}
        </HorizontalBar>
     
      {showClosest && (
        <HorizontalBar
          margin='0px 0px 40px 0px'
          gap={'40px'}
          displayResponsive='block'
          mediaQuery={'768'}
          direction={'column'}
        >
          <SecondaryButton disabled>Nelle vicinanze</SecondaryButton>
          <Slider
            defaultValue={maxDistance}
            min={10}
            max={80}
            step={10}
            onChange={(e) => {
              setMaxDistance(e);
            }}
            styles={{
              track: {
                backgroundColor: '#a7a3ff',
                height: '5px',
              },
            }}
            activeDotStyle={{
              backgroundColor: '#a7a3ff',
              borderColor: '#f0efff',
            }}
            // trackStyle={{ backgroundColor: "#a7a3ff", }}

            marks={heightMarks}
            dots
          />
        </HorizontalBar>
      )}

      <HorizontalBar 
      margin='30px 0px 20px 0px' 
      spaceBetween={true} 
    
      gap={'40px'}
      displayResponsive='block'
      mediaQuery={'768'}
      direction={'column'}
      >
        <SmallText>
          <span style={{ fontWeight: 'bold' }}>
            {!showClosest && data.filter((x) => filterData(x)).length }
            {showClosest && closeData.filter((x) => filterData(x)).length }
          </span>{' '}
          annunci disponibili - {' '}
          <span style={{ fontWeight: 'bold' }}>
            {!showClosest && Math.min(...[limit,data.filter((x) => filterData(x)).length])}
            {showClosest && Math.min(...[limit,closeData.filter((x) => filterData(x)).length])}
          </span>{' '}
          {!showClosest && " mostrati"}
          {showClosest && " mostrati"}

         
          {showClosest && `a ${maxDistance} Km da ${place?.raw.name} `}
        </SmallText>
        {userData?.role!=MUSICIAN && <div style={{display:'flex',flexDirection:'row', gap:10}}>
          <SecondaryButton style={statusFilter==null?{textDecoration:'underline'}:{}} onClick={()=>{setStatusFilter(null)}}>Tutti</SecondaryButton>
          <SecondaryButton style={statusFilter==AnnouncementStates.OPEN?{textDecoration:'underline'}:{}} onClick={()=>{setStatusFilter(AnnouncementStates.OPEN)}}>Attivi</SecondaryButton>
          <SecondaryButton style={statusFilter==AnnouncementStates.PENDING?{textDecoration:'underline'}:{}} onClick={()=>{setStatusFilter(AnnouncementStates.PENDING)}}>Pending</SecondaryButton>
          <SecondaryButton style={statusFilter==AnnouncementStates.CLOSED?{textDecoration:'underline'}:{}} onClick={()=>{setStatusFilter(AnnouncementStates.CLOSED)}}>Chiusi</SecondaryButton>
          <SecondaryButton style={statusFilter==AnnouncementStates.EXPIRED?{textDecoration:'underline'}:{}} onClick={()=>{setStatusFilter(AnnouncementStates.EXPIRED)}}>Scaduti</SecondaryButton>
          <SecondaryButton style={statusFilter==AnnouncementStates.CANCELED?{textDecoration:'underline'}:{}} onClick={()=>{setStatusFilter(AnnouncementStates.CANCELED)}}>Cancellati</SecondaryButton>
        </div>}
        {(userData?.role==PLANNER ||userData?.role==ADMIN) && <DeletableButton  onClick={()=>setNewAnnouncementPopup(true)}>
          Nuovo annuncio
        </DeletableButton>}
      </HorizontalBar>
      {instrumentPopup && (
        <Popup
          title={'Scegli fino a 5 strumenti'}
          onClose={() => setInstrumentPopup(false)}
        >
          <TwoColumnGridContainer>
            {musicalInstruments.map((x) => (
              <SelectableCard
                selected={selectedInstruments.includes(x.id)}
                onClick={() => toggleInstrument(x.id)}
                key={x.id}
              >
                {/* <TagIcon src={x.img} alt={x.name} $pt={'0'} /> */}
                <p>{x.label}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}

      {genrePopup && (
        <Popup
          title={'Scegli il genere'}
          content='Contenuto '
          onClose={() => setGenrePopup(false)}
        >
          <TwoColumnGridContainer>
            {genres.map((x) => (
              <SelectableCard
                selected={selectedGenres.includes(x.id)}
                onClick={() => toggleGenre(x.id)}
                key={x.id}
              >
                <p>{x.label}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}

      {newAnnouncementPopup && (
        <Popup
          title={'Nuovo annuncio'}
          content='Contenuto '
          onConfirm={() => {
            announcementSubmitFormRef.current.click();

            
          }}
          loading = {adding}
          onClose={() => {setNewAnnouncementPopup(false); setPendingAnnouncement(null);}}
        >
          {!adding && <AnnouncementForm
            ref={announcementFormRef}
            submitForm={handleSubmit}
            role ={userData.role}
            pendingAnnouncement={pendingAnnouncement}
            handlePayment={()=>handlePaymentAnnouncement(pendingAnnouncement.id)}
            submitRef={announcementSubmitFormRef}
            
          />}
          
          {adding && <div><ClipLoader /></div>}

        </Popup>
      )}

      
     

      
      {!loadingAnnouncements &&
        !data.filter((x) => filterData(x)).length &&
        place &&
        !showClosest && (
          <div
            style={{
              height: '50vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h4>:(</h4>
            <Text>
              Nessun annuncio disponibile a {place.label.split(',')[0]}{' '}
              {closeData.length <= 0 && 'e dintorni'}
            </Text>
            {closeData.length > 0 && (
              <SecondaryButton onClick={() => setShowClosest(true)}>
                Mostra nelle vicinanze
              </SecondaryButton>
            )}
          </div>
        )}
      {!loadingAnnouncements &&
        !data.filter((x) => filterData(x)).length &&
        !place && (
          <div
            style={{
              height: '50vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text>
              Nessun annuncio{' '}
              {userData && userData.role == ADMIN && notActiveFilter
                ? 'da validare'
                : 'disponibile'}
            </Text>
          </div>
        )}

      {!loadingAnnouncements &&
        !closeData.filter((x) => filterData(x)).length &&
        showClosest && (
          <div
            style={{
              height: '50vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h4>:(</h4>
            <Text>Nessun annuncio disponibile</Text>
          </div>
        )}

      {loadingAnnouncements && (
        <div
          style={{
            height: '50vh',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Loader />
        </div>
      )}

      {/** MAIN */}
      {!loadingAnnouncements && (
        <TwoColumnGridWithMargin>
          
        <TwoColumnGridAnnouncementContainer>    
          {data
            .filter((x) => filterData(x))
            .slice(0, limit)
            .map((announcement, i) => (
              <AnnouncementCard

                callback={updateData}
                actionsOpen={actionOpen == i}
                
                  setActionOpen={() =>
                    actionOpen == i ? setActionOpen(-1) : setActionOpen(i)
                  }
                  applied = {userData?.role==MUSICIAN && userData.applications.some(x=>x.announcement.id==announcement.id)}
                  onAccept={() => {console.log("Accept")}}
                  onConfirm={() => console.log("Confirm")}
                  onReject={() =>console.log("Reject")}
                  onApply={onApply}
                  onCancel={() => console.log("Cancel")}
                  onShow={() => console.log("Show")}
                  onEvaluate={() => console.log("Evaluate")}
                  onReview={() => console.log("Review")}
                role={userData?.role} 
                announcement={announcement} 
                status={AnnouncementStates.OPEN}/>
             
              
            ))}
            </TwoColumnGridAnnouncementContainer>    
        </TwoColumnGridWithMargin>
          
          
      )}
      {data.filter((x) => filterData(x)).length > limit && (
        <div style={{width:"100%", display:'flex',alignItems:'center',justifyContent:'center'}}>
          <DeletableButton onClick={() => setLimit(limit + 6)}>
            Mostra altri
          </DeletableButton>
        </div>
      )}
      {showClosest && closeData.length > 0 && (
        <div>
          {/** NWIGHBOROOD */}
          <TwoColumnGridWithMargin>
          
            <TwoColumnGridAnnouncementContainer>    
            {closeData
              .filter((x) => filterData(x))
              .slice(0, limit)
              .map((announcement, i) => (
                <AnnouncementCard
                callback={updateData}
                actionsOpen={actionOpen == i}
                
                  setActionOpen={() =>
                    actionOpen == i ? setActionOpen(-1) : setActionOpen(i)
                  }
                  applied = {userData?.role==MUSICIAN && userData.applications.some(x=>x.announcement.id==announcement.id)}
                  onAccept={() => {console.log("Accept")}}
                  onConfirm={() => console.log("Confirm")}
                  onReject={() =>console.log("Reject")}
                  onApply={onApply}
                  onCancel={() => console.log("Cancel")}
                  onShow={() => console.log("Show")}
                  onEvaluate={() => console.log("Evaluate")}
                  onReview={() => console.log("Review")}
                role={userData?.role} 
                announcement={announcement} 
                status={AnnouncementStates.OPEN}/>
                
              ))}
          </TwoColumnGridAnnouncementContainer>
         </TwoColumnGridWithMargin>
          
            {closeData.filter((x) => filterData(x)).length > limit && (
              <div style={{width:"100%", display:'flex',alignItems:'center',justifyContent:'center'}}>
              <DeletableButton onClick={() => setLimit(limit + 6)}>
                Mostra altri
              </DeletableButton>
              </div>
            )}
          </div>
        
      )}
    </>
  );
}

export default Announcements;
