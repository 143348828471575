import musicianIcon from "../assets/icons/musician.png";
import plannerIcon from "../assets/icons/planner.png";
import cardProfileImage from "../assets/tmp/me.jpeg";
import homeImage from "../assets/photo/ORCHESTRA_1692.jpg";
import coverImage from "../assets/photo/ORCHESTRA_1692.jpg";
import image from "../assets/tmp/img.png";
import bankTransferIcon from "../assets/icons/banktransfer.png";
import paypalIcon from "../assets/icons/paypal.png";
import musicBanner from "../assets/photo/ORCHESTRA_1692.jpg";
import canto from "../assets/tmp/canto.jpeg";
import guitar1 from "../assets/tmp/guitar1.webp";
import piano from "../assets/tmp/piano.jpeg";
import provaDallE from "../assets/tmp/provaDallE.png";
import coverImg from "../assets/tmp/martina.png";
import vivianaImg from "../assets/tmp/viviana.jpg";
import marinaImg from "../assets/tmp/viviana2.jpg";
import chiSiamoImg from "../assets/photo/ORCHESTRA_1890.jpg";
import orchestraLogo from "../assets/logo/orchestra.png";
import missionImg from "../assets/photo/ORCHESTRA_1772.jpg";
import iscrizioneGIF from "../assets/iscrizione.gif";
import consigliamiButton from "../assets/elements/consigliami.png";
import contactsImg from "../assets/photo/ORCHESTRA_1660.jpg";
import garlascoImg from "../assets/photo/GARLASCO_SQUARED.jpeg";

// EXERCISES
// ERRORS

/**
 * In this dictionary all the images for the app are pre-loaded so that can be easily included in any point of the app
 * Examples of usage: src = {Images["exercises"][TREE]}
 * @author   Giuseppe Pastore
 * @return   {Dictionary}
 */
const Images = {
  icons: {
    planner: plannerIcon,
    musician: musicianIcon,
    bankTransferIcon,
    paypalIcon,
  },
  tmp: {
    cardProfileImage: cardProfileImage,
    home: homeImage,
    cover: coverImage,
    img: image,
    banner: musicBanner,
    canto: canto,
    guitar1: guitar1,
    piano: piano,
    provaDallE: provaDallE,
    coverImg: coverImg,
    viviana: vivianaImg,
    marina: marinaImg,
    chiSiamo: chiSiamoImg,
    mission: missionImg,
    testGIF: iscrizioneGIF,
    contatti: contactsImg,
    garlasco: garlascoImg
  },
  logo: {
    orchestra: orchestraLogo
  },
  elements: {
    consigliamiButton: consigliamiButton

  }
};
export default Images;
