import { useClerk, useUser } from '@clerk/clerk-react';
import { faCalendar, faEuroSign, faLocationDot, faUser, faUsers, faHourglassHalf, faClock } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { AnnouncementStates } from '../../../../config/AnnouncementConsts';
import { ADMIN, MUSICIAN, PLANNER } from '../../../../config/profile/Roles';
import genres from '../../../../data/genres';
import musicalInstruments from '../../../../data/instruments';
import { applyToTheAnnouncement, cancelAnnouncement, closeAnnouncement, enableAnnouncement } from '../../../../remote/announcements';
import { createPayment, createPaymentApplication } from '../../../../remote/payments';
import { Paragraph } from '../../../../screens/home/HomeElements';
import { useStateValue } from '../../../../stores/StateProvider';
import getToken from '../../../../userManagement/utilities';
import { abbreviateSurname, dateTimeToString } from '../../../../utils/String';
import Popup from '../../../functionals/Popup/Popup';
import HorizontalBar from "../../bars/HorizontalBar/HorizontalBar";
import DeletableButton from '../../buttons/DeletableButton/DeletableButton';
import { IconButtonContainer } from '../../buttons/IconButton/IconButtonElements';
import SecondaryButton from '../../buttons/SecondaryButton/SecondaryButton';
import { VerticalLayout } from "../../Containers/layouts/VerticalLayout/VerticalLayout";
import { SmallText, TextLink, Title2 } from "../../texts/TextsElements";
import { Tag } from '../MainCard/MainCardElements';
import { AnnouncementCardContainer, Description, HorizontalListContainer, MoreLink } from "./AnnouncementCardElements";
import { StyledLink } from '../../Header/HeaderElements';




const AnnouncementCard = ({action=true, onApply,applied, announcement, role, callback}) => {

  const [copied,setCopied] = useState(false)


  const daysRemainingF = ()=>{
        // 1. Parse della `creationDate`
      const creationDate = new Date(announcement.creationDate);

      // 2. Calcolo della data di scadenza (aggiungi 30 giorni)
      const expirationDate = new Date(creationDate);
      expirationDate.setDate(creationDate.getDate() + 30);

      // 3. Calcolo dei giorni rimanenti
      const today = new Date();
      const timeDifference = expirationDate.getTime() - today.getTime();

      // Converti la differenza di tempo da millisecondi a giorni
      const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      return daysRemaining;
  }

  const handleCopyClick = async (id) => {
    try {
      await navigator.clipboard.writeText("https://www.orchestra.srl/announcements/"+id); // Copia il testo nella clipboard
      setCopied(true); // Imposta lo stato su "copiato"
      setTimeout(() => setCopied(false), 2000); // Nasconde l'avviso dopo 2 secondi
    } catch (err) {
      console.error('Failed to copy text: ', err); // Gestisce errori di copia
    }
  };

  const { session } = useClerk();
  const [loading, setLoading] = useState(false);

  let today = new Date()

  const [showMore, setShowMore] = useState(false)
  
  const [userId, setUserId] = useState(undefined);
  const { user } = useUser();
  useEffect(() => {
    setUserId(user.id);
  }, [user]);

  today.setHours(0,0,0,0);
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  


  





    const handleCloseAnnouncement = async(id)=>{
      setLoading(true)
      let t = await getToken(session);
      
      await closeAnnouncement(id,t)
      
      await callback()
      setLoading(false)
    }

    const handlePaymentAnnouncement = async(id) => {
      setLoading(true)

      const jwtToken = await session.getToken();

      
      // pay
      
      const response = await createPayment(
        jwtToken,
        {
          userId: userId,
          announcementId: id,
        }
      );

      if(response.ok) {
          response.json().then(json => {            
              if(json.url) {
                 window.location.href = json.url;
              } else {
                  setMessage('Qualcosa è andato storto');
              }
          });
      } else {
          setMessage('Qualcosa è andato storto');
      }

      setLoading(false)
    }
  
    const handleEnableAnnouncement = async(id)=>{
      setLoading(true)
      let t = await getToken(session);
      
      await enableAnnouncement(id,t)
      
      await callback()
      setLoading(false)
    }

    const handleCancelAnnouncement = async(id)=>{
      setLoading(true)
      let t = await getToken(session);
      
      await cancelAnnouncement(id,t)
      
      await callback()
      setLoading(false)
    }

    const handlePaymentApplication = async(id,type, jwtToken) => {

      setLoading(true)
   
  
      
      // pay
      
      const response = await createPaymentApplication(
        jwtToken,
        {
          userId: userData.id,
          announcementId: id,
          announcementType: type
        }
      );
  
      if(response.ok) {
          response.json().then(json => {            
              if(json.url) {
                 window.location.href = json.url;
              } 
          });
      } 
  
     
      setLoading(false);
    
    }
  

    const handleApplyToTheAnnouncement = async(announcement)=>{
      let id = announcement.id
      setLoading(true)
      let t = await getToken(session);

      if (announcement.price && announcement.price>0){
        await handlePaymentApplication(id,announcement.title.includes("presenza")?2:1,t)
      }
      else{
        await applyToTheAnnouncement(id,t)
      }
      await onApply()
      setLoading(false)
    }

    const [{ userData }] = useStateValue();




    return (
        
      <>

      {showMore && <Popup
                    title={'Titolo'}
                    content='Contenuto del Popup'
                    confirmButtonLabel=''
                    onClose={()=>setShowMore(false)}
                    textAlign='center'
                    onConfirm={null}
                    
                  >
                    {announcement.description}
                    </Popup>}
      
      <AnnouncementCardContainer onClick={()=>navigate("/announcements/"+announcement.id)}>
     
        <>
 
          <VerticalLayout bkgColor={"transparent"} flex={6}>
            <HorizontalBar spaceBetween={false}>

              <div style={{paddingRight:"10px"}}>
                <IconButtonContainer
                  icon={faCalendar}
                  color={'#9A84BC'}
                  size={'s'}
                />
              </div>
              <SmallText>{dateTimeToString(announcement.date)}</SmallText>
              
                 
             </HorizontalBar>
             <HorizontalBar spaceBetween={false}>
             
             <div style={{paddingRight:"10px"}}>
                <IconButtonContainer
                  icon={faLocationDot}
                  color={'#9A84BC'}
                  size={'s'}
                />
              </div>
              <SmallText >
                {announcement.location.raw.display_name}
              </SmallText>
           
              
                 
             </HorizontalBar>
            {announcement.admin && announcement.price && announcement.price>0 && <HorizontalBar spaceBetween={false}>
             
              <div style={{paddingRight:"10px"}}>
                <IconButtonContainer
                  icon={faEuroSign}
                  color={'#9A84BC'}
                  size={'s'}
                />
              </div>
             <SmallText >
               Candidatura a pagamento: {announcement.price} €
             </SmallText>
          
             
                
            </HorizontalBar>}
            {!announcement.admin && announcement.budget &&  <HorizontalBar spaceBetween={false}>
             
              <div style={{paddingRight:"10px"}}>
                <IconButtonContainer
                  icon={faEuroSign}
                  color={'#9A84BC'}
                  size={'s'}
                />
              </div>
             <SmallText >
               {announcement.budget} €
             </SmallText>
          
             
                
            </HorizontalBar>}
            <HorizontalBar spaceBetween={false}>
             
             <div style={{paddingRight:"10px"}}>
               <IconButtonContainer
                 icon={faUser}
                 color={'#9A84BC'}
                 size={'s'}
               />
             </div>
            {announcement.admin?<SmallText >
              Orchestra
            </SmallText>:<SmallText >
              {announcement?.planner?.company && announcement?.planner?.company?.name?announcement?.planner?.company.name:announcement?.planner?.firstName+ " "+ (role==ADMIN?announcement?.planner?.lastName:(announcement?.planner?.lastName?abbreviateSurname(announcement?.planner?.lastName):""))}
            </SmallText>}
         
            
               
           </HorizontalBar>
           <HorizontalBar spaceBetween={false}>
             
             <div style={{paddingRight:"10px"}}>
               <IconButtonContainer
                 icon={faUsers}
                 color={'#9A84BC'}
                 size={'s'}
               />
             </div>
            {announcement.group?<SmallText >
              Sta cercando un {announcement.groupType}
            </SmallText>:<SmallText >
              Sta cercando  {announcement.nMusicians} musicisti singoli
            </SmallText>}
            
            
               
           </HorizontalBar>
           {role==ADMIN && <HorizontalBar spaceBetween={false}>
            <div style={{paddingRight:"10px"}}>
                <IconButtonContainer
                  icon={faHourglassHalf}
                  color={'#9A84BC'}
                  size={'s'}
                />
              </div>
              <SmallText>
                {daysRemainingF()<=0?"Scaduto":daysRemainingF()+" giorni rimasti"} 
              </SmallText>
            </HorizontalBar>}
             <HorizontalBar spaceBetween={true}>
              <Title2><span style={{fontWeight:'bold'}}>{announcement.title?announcement.title:'Senza titolo'}</span></Title2>
              
                 
             </HorizontalBar>
            
             <HorizontalBar spaceBetween={true}>
              <div style={{paddingBottom:40, display:'flex',flexDirection:'column',justifyContent:'flex-start', alignItems:'flex-start'}}>
              <Description>{announcement.description}</Description>
                {announcement.description.length > 100 && (
                  <MoreLink onClick={()=>setShowMore(true)}>mostra altro</MoreLink>
                )}
              </div>
              
                 
             </HorizontalBar>
             
          
             
             {/* <HorizontalBar >
                <SmallText>Creato il {dateTimeToString(announcement.creationDate)} - Scade il {dateTimeToString(announcement.creationDate)}</SmallText> 
               
             </HorizontalBar> */}

             <HorizontalBar >
                
                <HorizontalListContainer>
                
                    {announcement.instruments?.map((instrument, index) => {
                      let obj = musicalInstruments.filter((x) => x.id === instrument)[0]
                      return (
                        <Tag>
                          {/* <TagIcon
                            key={index}
                            src={
                              obj.img
                            }
                            
                            alt={obj.name}
                          /> */}
                           <SmallText style={{color:'#9A84BC'}}>{obj?.label}</SmallText>
                      </Tag>
                      );
                    })}
                    </HorizontalListContainer>
                  
            
             </HorizontalBar>
             <HorizontalBar>
                <HorizontalListContainer>
                    {announcement.genres?.map((genre, index) => {
                      let obj = genres.filter((x) => x.id === genre)[0]
                      return (
                        <Tag key={index}>
                          {/* <TagIcon
                            key={index}
                            src={
                              obj.img
                            }
                            alt={obj.name}
                          /> */}
                          <SmallText style={{color:'#9A84BC'}}>{obj?.label}</SmallText>
                      </Tag>
                      );
                    })}
                  </HorizontalListContainer>
             </HorizontalBar>
            

          
           </VerticalLayout>

        

          <div style={{ flex:2,height:'100%', display:'flex', alignItems:'center',justifyContent:'center'}}>
           {role==MUSICIAN && action && !loading && !applied && <div style={{flex:1, display:'flex', alignItems:'center',justifyContent:'center'}}>
            {/* <HamBurgerMenu onClick={setActionOpen}>
                <FontAwesomeIcon icon={faEllipsisV} />
              </HamBurgerMenu> */}
              {userData?.valid && 
                <div style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
                  <SecondaryButton  onClick={(e)=>{handleApplyToTheAnnouncement(announcement); e.stopPropagation()}}>{announcement.price && announcement.price>0?"Paga e candidati":"Candidati"}</SecondaryButton>
                  <SmallText $textAlign={"center"}>{announcement.limit?announcement.limit:"5"} candidature disponibili</SmallText>
                </div >
                }

              {!userData?.valid && 
                 <Paragraph $textAlign={"center"} >Il tuo profilo è ancora in valutazione</Paragraph>}
            </div>}
            {role==MUSICIAN && action && applied && <div style={{flex:1, display:'flex', alignItems:'center',justifyContent:'center'}}>
            {/* <HamBurgerMenu onClick={setActionOpen}>
                <FontAwesomeIcon icon={faEllipsisV} />
              </HamBurgerMenu> */}
              <Paragraph $textAlign={"center"} >Hai già applicato a questo annuncio</Paragraph>
            </div>}
            {(role==PLANNER || role==ADMIN) && !loading && action &&  <div style={{flex:1, flexDirection:'column', alignItems:'center', justifyContent:'center', display:'flex'}}>
     
             
              {announcement.status==AnnouncementStates.OPEN && <DeletableButton
                              onClick={(e) => {if (role==ADMIN){
                                                  console.log("Admin eccolo qua!")
                                                  navigate('/admin/announcements/'+announcement.id+"/applications"); 
                                              }
                                              else 
                                                  navigate('/me/announcements/'+announcement.id+"/applications")
                                                  e.stopPropagation()
                                                }
                                               
                                                  
                                                }
                                              
                           
                            >
                              Guarda candidati
                            </DeletableButton>}
              {announcement.status==AnnouncementStates.OPEN && <SecondaryButton onClick={(e)=>{handleCloseAnnouncement(announcement.id);e.stopPropagation() }}>Chiudi</SecondaryButton>}
              {announcement.status==AnnouncementStates.PENDING && 
                <div style={{display:'flex',alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                  {message && (<p>
                    {message}
                  </p>)}
                  {userId && role==PLANNER &&(
                    <DeletableButton onClick={(e)=>{
                      handlePaymentAnnouncement(announcement.id)
                      e.stopPropagation();
                    }}>Paga</DeletableButton>
                  )}
                  
                  {role==ADMIN && <DeletableButton onClick={(e)=>{
                    handleEnableAnnouncement(announcement.id);
                  e.stopPropagation()}
                    }>Attiva</DeletableButton>}
                  <SecondaryButton onClick={(e)=>{
                    handleCancelAnnouncement(announcement.id);
                    e.stopPropagation()
                  }
                  
                  }>Annulla</SecondaryButton>
                </div>
                }

              {announcement.status==AnnouncementStates.CANCELED && 
                              <Paragraph style={{color:'#9A84BC'}} $textAlign={"center"} >Annullato</Paragraph>
                              }
              {announcement.status==AnnouncementStates.EXPIRED && 
                              <Paragraph style={{color:'#9A84BC'}} $textAlign={"center"} >Scaduto</Paragraph>
                              }


            {!copied && <div style={{display:'flex', flexDirection:'row', gap:10}}>
            
                        <TextLink style={{fontSize:"14px"}} onClick={(e)=>{
                          handleCopyClick(announcement.id);
                          e.stopPropagation();
                        }
                        }><span>🔗 </span>Condividi<span style={{opacity:0}}>🔗 </span></TextLink>
                        </div>
            }
            {copied && <TextLink style={{ marginLeft: '10px' }}>Link copiato!</TextLink>}

        
             
            </div>}
            {loading && <div style={{flex:1}}>
                <ClipLoader />
              </div>}
            
          </div>
         </>
 
         
        
       </AnnouncementCardContainer>
      
       </>)
    


  };

  
  export default AnnouncementCard;