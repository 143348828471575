import { useClerk, useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { MUSICIAN } from "../../config/profile/Roles";
import { getMe } from "../../remote/me";
import Loader from "../../utils/Loader";
import OnboardingSteps from "./OnboardingSteps/OnboardingSteps";
import getToken from "../utilities";


export default function Onboarding() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { user } = useUser();
  const {session} = useClerk();

  // Esegui la chiamata remota asincrona qui
  async function fetchData() {
    console.log('£Hey fetching');
    setIsLoading(true);
    try {
      // Eseguire una chiamata remota con await
      console.log('User id: ', user.id);
      let t = await getToken(session);

      const userObj = await getMe(t);
      // const userObj = {
      //     role: MUSICIAN,
      //     isOnboardingCompleted: false
      // }

      
      setUserData(userObj);

      // Esegui ulteriori elaborazioni con la risposta, se necessario
      console.log('Risposta dalla chiamata remota: ', userObj);
    } catch (error) {
      console.error('Errore nella chiamata remota: ', error);
    } finally {
      // Imposta isLoading su false per indicare che la chiamata è stata completata
      setIsLoading(false);
    }
  }

  useEffect(() => {
    console.log('User effect...');

    fetchData();
  }, []); // L'array vuoto [] garantisce che questa chiamata venga eseguita solo una volta all'inizio

    if (isLoading) {
      // Se la chiamata remota è in corso, puoi mostrare uno spinner o un messaggio di caricamento
      return <Loader />;
    } else if (!userData) {
      fetchData()
      return <></>;
    } else if (userData.isOnboardingCompleted) {
      return <Navigate to='/' replace={true} />;
    } else return <OnboardingSteps isMusicianRole={userData.role === MUSICIAN} />;
}