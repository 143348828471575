import { useClerk, useUser } from "@clerk/clerk-react";
import {
  faBars,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { ADMIN, MUSICIAN, PLANNER } from '../../../config/profile/Roles';
import { RESET } from '../../../config/URLs';
import { Paragraph } from '../../../screens/home/HomeElements';
import { GENERAL } from '../../../stores/actions/GeneralActions';
import { useStateValue } from '../../../stores/StateProvider';
import SignInPopup from '../../../userManagement/signIn/signInPopup';
import Images from '../../../utils/Images';
import Loader from '../../../utils/Loader';
import Popup from '../../functionals/Popup/Popup';
import HoverButton from '../buttons/HoverButton/HoverButton';
import SecondaryButton from '../buttons/SecondaryButton/SecondaryButton';
import { LeftText } from '../texts/TextsElements';
import {
  Box,
  CloseIcon,
  Email,
  FullScreenMenu,
  HamBurgerMenu,
  HeaderContainer,
  HeaderContent,
  HideDesktopDiv,
  Menu,
  ProfileAvatar,
  RightElements,
  StyledA,
  StyledLink,
  StyledText,
} from './HeaderElements';
import { useNavigate } from "react-router-dom";

export const Header = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { user, isSignedIn, isLoaded } = useUser();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const [{ userData }] = useStateValue();
  const { signOut } = useClerk();
  const menuRef = useRef();
  const mobileMenuRef = useRef();
  const [state, dispatch] = useStateValue();

  useEffect(()=>{
    console.log("hei: ",props)
    setIsMobileMenuOpen(false)
  },[props.toggle])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isSignedIn) {
      setShowLoginPopup(false);
      setIsMobileMenuOpen(false);
      setIsMenuOpen(false);
    }
  }, [isSignedIn]);

  const navigate = useNavigate(); 


  const handleClickOutside = (event) => {


    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      setIsMobileMenuOpen(false);
    }
    if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
   
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen, isMobileMenuOpen]);

  if (window.location.href.includes("/auth"))
    return (<>
        <HeaderContainer home={props.home}>
          {showLogoutPopup && (
            <Popup
              title={'Sei sicuro di volere uscire dall\'applicazione?'}
              content='Contenuto del Popup'
              onClose={() => {
                setShowLogoutPopup(false);
              }}
              onConfirm={() => {
                setShowLogoutPopup(false);
                signOut();
                dispatch({type:GENERAL,subtype:RESET})
              }}
              textAlign={'center'}
            ></Popup>
          )}
          <HeaderContent $width={!isSignedIn}>
          <div style={{ visibility: props.home ? 'hidden' : '', width: props.home ? '20%' : 'auto' }}>
            
            <LeftText
              onClick={() =>
                (document.location.href = document.location.origin)
              }
            >
              <img src={Images.logo.orchestra} style={{ height: '20px' }} />
            </LeftText>
          </div>
          <RightElements>
            {isSignedIn && <SecondaryButton onClick={()=>setShowLogoutPopup(true)}>Logout</SecondaryButton>}
          </RightElements>
          </HeaderContent>
        </HeaderContainer>
      </>
  )

 

  return (
    <>
    
      <HeaderContainer home={props.home}>
        {showLoginPopup && (
          <SignInPopup setShowLoginPopup={() => setShowLoginPopup(false)} />
        )}
        {showLogoutPopup && (
          <Popup
            title={'Sei sicuro di volere uscire dall\'applicazione?'}
            content='Contenuto del Popup'
            onClose={() => {
              setShowLogoutPopup(false);
            }}
            onConfirm={() => {
              setShowLogoutPopup(false);
              signOut();
              dispatch({type:GENERAL,subtype:RESET})
            }}
            textAlign={'center'}
          ></Popup>
        )}

        <HeaderContent $width={!isSignedIn}>
          <div style={{ visibility: props.home ? 'hidden' : '', width: props.home ? '20%' : 'auto' }}>
            
            <LeftText
              onClick={() =>
                (document.location.href = document.location.origin)
              }
            >
              <img src={Images.logo.orchestra} style={{ height: '20px' }} />
            </LeftText>
          </div>
          <RightElements>
            {/* {userData?.role === MUSICIAN && (
              <StyledLink to='/announcements'>Bacheca annunci</StyledLink>
            )} */}
            {/* { !window.location.href.endsWith('/musicians') && userData?.role != MUSICIAN  &&  userData?.role != PLANNER &&  userData?.role != ADMIN &&  (
              <HoverButton
              onClick={() => {
                navigate("/musicians")
              }}
              bgColor={'transparent'}
              color={'#A7A3FF'}
            >
              <span style={{fontFamily:'Raleway Regular',}} >Crea il tuo annuncio</span>
            </HoverButton>
             
            )} */}
            { !window.location.href.includes('/announcements') && userData?.role != ADMIN && userData?.role != MUSICIAN &&  (
              <HoverButton
              onClick={() => {
                navigate("/me/announcements?new=1")
              }}
              bgColor={'transparent'}
              color={'#A7A3FF'}
            >
              <span style={{fontFamily:'Raleway Regular',}} >Crea il tuo annuncio</span>
            </HoverButton>
             
            )}
            {!isLoaded && <Loader />}
            {isLoaded && (
              <>
                {/* {isSignedIn && <FavoritesButton wishlist={true}/>} */}
                {/* {!isSignedIn && <SecondaryButton onClick={() => window.location.href = 'mailto:giuseppe.pastore1017@gmail.com'}>Contattaci</SecondaryButton>} */}
                {!isSignedIn && (
                  <>
                  <HoverButton
                    onClick={() => {
                      setShowLoginPopup(true);
                      // (document.location.href = document.location.origin + '/me')
                    }}
                    bgColor={'transparent'}
                    color={'#A7A3FF'}
                  >
                    <span style={{fontFamily:'Raleway Regular'}} >Registrati/Accedi</span>
                  </HoverButton>
                 
                  <HamBurgerMenu onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                    <FontAwesomeIcon
                      color={'#000'}
                      style={{width:'18px'}}
                      icon={faBars}
                    />
                  </HamBurgerMenu>
                  </>
                )}
                
                {isSignedIn &&  (
                  <>
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        marginRight: '5px',
                      }}
                    >
                     {!window.location.href.includes("/onboarding") && <ProfileAvatar
                        src={user.imageUrl}
                        onClick={toggleMenu}
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                        $isBorderShown={userData?.role === MUSICIAN && !userData?.valid}
                      ></ProfileAvatar>}
                      {window.location.href.includes("/onboarding") && 
                      <SecondaryButton onClick={() => {
                        setShowLogoutPopup(true);
                        setIsMenuOpen(false);
                      }}>Logout</SecondaryButton>}
                      {isSignedIn &&
                        !userData?.valid &&
                        userData?.role === MUSICIAN &&
                        !window.location.href.includes("/onboarding") && 
                        isShown && (
                          <div
                            style={{
                              position: 'absolute',
                              right: '50px',
                              display: 'flex',
                              gap: '5px',
                              alignItems: 'center',
                            }}
                          >
                            <StyledText>
                              Stiamo ancora verificando il tuo profilo
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                color='#DF6951'
                                size='lg'
                                style={{ marginLeft: '15px' }}
                              ></FontAwesomeIcon>
                            </StyledText>
                          </div>
                        )}
                      <Menu ref={menuRef} $isOpen={isMenuOpen}>
                        {/* <Email>{user.primaryEmailAddress.emailAddress}</Email> */}
                        {(userData?.role === MUSICIAN ||
                          userData?.role === PLANNER) && (
                          <>
                            <StyledLink
                              to='/me/profile'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Il mio account
                            </StyledLink>
                          </>
                        )}
                        {userData?.role === MUSICIAN && (
                          <>
                         <StyledLink
                          to='/announcements'
                          onClick={() => setIsMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Ricerca annunci
                        </StyledLink>
                        <StyledLink
                          to='/garlasco'
                          onClick={() => setIsMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Rinnovisi la giostra!
                        </StyledLink>
                            <StyledLink
                              to='/me/applications'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Le mie candidature
                            </StyledLink>
                          </>
                        )}
                        {userData?.role === PLANNER && (
                          <>
                            <StyledLink
                              to='/me/announcements'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              I miei annunci
                            </StyledLink>
                           
                            <StyledLink
                              to='/musicians'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                            Trova il tuo musicista
                            </StyledLink>
                            {/* <StyledLink
                              to='/me/announcements'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Le mie consulenze
                            </StyledLink> */}
                          </>
                        )}
                        {userData?.role === ADMIN && (
                          <>
                            <StyledLink
                              to='/admin/musicians'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Gestione musicisti
                            </StyledLink>
                            <StyledLink
                              to='/admin/planners'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Gestione organizzatori
                            </StyledLink>
                            <StyledLink
                              to='/admin/announcements'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Gestione annunci
                            </StyledLink>
                          </>
                        )}
                        
                        <SecondaryButton
                          onClick={() => {
                            setShowLogoutPopup(true);
                            setIsMenuOpen(false);
                          }}
                        >
                          logout
                        </SecondaryButton>
                      </Menu>
                    </div>
                  </>
                )}
              </>
            )}
            {!isSignedIn && isMobileMenuOpen &&
            <Menu ref={mobileMenuRef} $isOpen={isMobileMenuOpen}>
            
            
              <>
              
              <StyledA
                      href='/'
                      onClick={() => setIsMobileMenuOpen(false)}
                      $color={'#000'}
                      $fontWeight={'normal'}
                    >
                     Home
                    </StyledA>
                    {userData?.role === MUSICIAN && <StyledA
                          href='/announcements'
                          onClick={() => setIsMobileMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Ricerca annunci
                        </StyledA>}
                <StyledA
                      href='/chi-siamo'
                      onClick={() => setIsMobileMenuOpen(false)}
                      $color={'#000'}
                      $fontWeight={'normal'}
                    >
                     Chi siamo 
                    </StyledA>
                    
                    <StyledA
                      href='mission'
                      onClick={() => setIsMobileMenuOpen(false)}
                      $color={'#000'}
                      $fontWeight={'normal'}
                    >
                     Mission
                    </StyledA>
                    <StyledA
                      href='garlasco'
                      onClick={() => setIsMobileMenuOpen(false)}
                      $color={'#000'}
                      $fontWeight={'normal'}
                    >
                     Rinnovisi la giostra!
                    </StyledA>
                   
                    <StyledA
                      href='contacts'
                      onClick={() => setIsMobileMenuOpen(false)}
                      $color={'#000'}
                      $fontWeight={'normal'}
                    >
                     Contatti
                    </StyledA>
                  
                   
                   
              </>
            
            </Menu>
            }
          </RightElements>
          {/* {!isSignedIn && (
            <HideDesktopDiv $gap>
              {!window.location.href.endsWith('/musicians') && !window.location.href.endsWith('/musicians') && (
                <StyledLink to='/musicians'>Bacheca musicisti</StyledLink>
              )}
            
            </HideDesktopDiv>
          )} */}

          {!isSignedIn && (
            <HideDesktopDiv>
           
           <HoverButton
                    onClick={() => {
                      setShowLoginPopup(true);
                      // (document.location.href = document.location.origin + '/me')
                    }}
                    bgColor={'transparent'}
                    color={'#A7A3FF'}
                  >
                    <span style={{fontFamily:'Raleway Regular'}} >Registrati/Accedi</span>
                  </HoverButton>
              <HamBurgerMenu onClick={() => setIsMobileMenuOpen(true)}>
                <FontAwesomeIcon
                  color={props.home ? '#fff' : '#000'}
                  icon={faBars}
                />
              </HamBurgerMenu>
              {isMobileMenuOpen && (
                <FullScreenMenu>
                  <CloseIcon onClick={() => setIsMobileMenuOpen(false)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </CloseIcon>
                  <>
                  <div style={{ margin: '65px 0 20px 0' }}>
                      
                    </div>
                    
                    
                      <>
                      <img src={Images.logo.orchestra} style={{ height: '20px' }} />
                      <StyledA
                          href='/'
                          onClick={() => setIsMobileMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Home
                        </StyledA>
                        {userData?.role === MUSICIAN && <StyledA
                          href='/announcements'
                          onClick={() => setIsMobileMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Ricerca annunci
                        </StyledA>}

                       
                        <StyledA
                          href='/chi-siamo'
                          onClick={() => setIsMobileMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Chi siamo 
                        </StyledA>
                        <StyledA
                          href='mission'
                          onClick={() => setIsMobileMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Mission
                        </StyledA>
                        <StyledA
                          href='garlasco'
                          onClick={() => setIsMobileMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Rinnovisi la giostra!
                        </StyledA>
                        <StyledA
                          href='/me/announcements?new=1'
                          onClick={() => setIsMobileMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Crea il tuo annuncio
                        </StyledA>
                        <StyledA
                          href='/contacts'
                          onClick={() => setIsMobileMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Contatti
                        </StyledA>
                        
                        
                       
                      </>
                    
                   
                    
                  </>
                </FullScreenMenu>
              )}
              
            </HideDesktopDiv>
            
          )}

         
          
          {isSignedIn  &&  (
            <HideDesktopDiv>
            {/* {userData?.role === MUSICIAN && (
                <StyledLink to='/musicians'>Ricerca annunci</StyledLink>
              )} */}
               { !window.location.href.includes('announcements') && userData?.role && userData?.role != MUSICIAN  &&   userData?.role != ADMIN &&  (
                  <HoverButton
                  onClick={() => {
                    navigate("/me/announcements?new=1")
                  }}
                  bgColor={'transparent'}
                  color={'#A7A3FF'}
                >
                  <span style={{fontFamily:'Raleway Regular',}} >Crea il tuo annuncio</span>
                </HoverButton>
                
                )}
              {!window.location.href.includes("/onboarding") && <HamBurgerMenu onClick={() => setIsMobileMenuOpen(true)}>
                <FontAwesomeIcon
                  color={props.home ? '#fff' : '#000'}
                  icon={faBars}
                />
              </HamBurgerMenu>}
              {window.location.href.includes("/onboarding") && <SecondaryButton
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setShowLogoutPopup(true);
                      }}
                    >
                      Logout
                    </SecondaryButton> }
              {isMobileMenuOpen &&  (
                <FullScreenMenu>
                  <CloseIcon onClick={() => setIsMobileMenuOpen(false)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </CloseIcon>
                  <>
                    <div style={{ margin: '65px 0 20px 0' }}>
                      <ProfileAvatar
                        src={user.imageUrl}
                        $width={'100px'}
                        $height={'100px'}
                        // onClick={toggleMenu}
                      />
                    </div>
                    {/* <Email>{user.primaryEmailAddress.emailAddress}</Email> */}
                    {(userData?.role === MUSICIAN ||
                          userData?.role === PLANNER) && (
                          <>
                            <StyledLink
                              to='/me/profile'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Il mio account
                            </StyledLink>
                          </>
                        )}
                        {userData?.role === MUSICIAN && (
                          <>
                          <StyledLink
                          to='/announcements'
                          onClick={() => setIsMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Ricerca annunci
                        </StyledLink>
                        <StyledLink
                          to='/garlasco'
                          onClick={() => setIsMenuOpen(false)}
                          $color={'#000'}
                          $fontWeight={'normal'}
                        >
                         Rinnovisi la giostra!
                        </StyledLink>
                            <StyledLink
                              to='/me/applications'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Le mie candidature
                            </StyledLink>
                          </>
                        )}
                        {userData?.role === PLANNER && (
                          <>
                            <StyledLink
                              to='/me/announcements'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              I miei annunci
                            </StyledLink>
                            
                            
                            <StyledLink
                              to='/musicians'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                             Trova il tuo musicista
                            </StyledLink>
                            {/* <StyledLink
                              to='/me/requests'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Le mie consulenze
                            </StyledLink> */}
                          </>
                        )}
                        {userData?.role === ADMIN && (
                          <>
                            <StyledLink
                              to='/admin/musicians'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Gestione musicisti
                            </StyledLink>
                            <StyledLink
                              to='/admin/planners'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Gestione organizzatori
                            </StyledLink>
                            <StyledLink
                              to='/admin/announcements'
                              onClick={() => setIsMenuOpen(false)}
                              $color={'#000'}
                              $fontWeight={'normal'}
                            >
                              Gestione annunci
                            </StyledLink>
                          </>
                        )}
                       
                    <SecondaryButton
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setShowLogoutPopup(true);
                      }}
                    >
                      logout
                    </SecondaryButton>
                  </>
                </FullScreenMenu>
              )}
            </HideDesktopDiv>
          )}
        </HeaderContent>
      </HeaderContainer>






      {isSignedIn && !userData?.valid && userData?.role === MUSICIAN && !window.location.href.includes("/onboarding") &&  (
        <Box style={{ display: props.home && 'none' }}>
          <Paragraph $textColor={'#fff'} $textAlign={'center'}>
            Stiamo ancora verificando il tuo profilo
          </Paragraph>
        </Box>
      )}
    </>
  );
};

export default Header;
